import {
  EscalationActionTypes,
  GET_ESCALATION_DETAILS,
  GET_ESCALATION_LIST,
  GET_ESCALATION_STATS,
  GET_ESCALATION_REASON_OPTIONS,
  GET_ESCALATION_CONVERSATION,
  GET_UNMASKED_NUMBER,
} from "store/constants/Escalation";

const initialState = {
  escalationList: {
    isLoading: false,
    data: [],
  },
  statsCard: {
    isLoading: false,
    data: [],
  },

  details: {
    isLoading: false,
    data: [],
  },
  escalationReasonOptions: [],

  conversation: {
    isLoading: true,
    data: [],
  },
  unmaskedData: {
    data: null,
  },
};

export default function escalationReducer(
  state: any = initialState,
  action: EscalationActionTypes
) {
  switch (action.type) {
    case GET_ESCALATION_LIST:
      return {
        ...state,
        escalationList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_ESCALATION_STATS:
      return {
        ...state,
        statsCard: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_ESCALATION_DETAILS:
      return {
        ...state,
        details: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_ESCALATION_REASON_OPTIONS:
      return {
        ...state,
        escalationReasonOptions: action.data,
      };
    case GET_ESCALATION_CONVERSATION:
      return {
        ...state,
        conversation: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedData: {
          data: action.data,
        },
      };
    default:
      return { ...state };
  }
}
