import {
  GET_RETAILER_SALES_ANALYSIS,
  MY_RETAILERS_LIST,
  MY_RETAILERS_PROFILE,
  GET_RETAILER_PREVIOUS_SALESPERSON,
  GET_RETAILER_UNMASKED_DATA,
  GET_VISIT_HISTORY_DETAILS,
} from "store/constants/RetailerManagement";
import { IRetailerState, IRetailerActionType } from "types/retailerTypes";

const inititalState = {
  assignedRetailers: { results: [] },
  retailersList: { results: [] },
  retailersProfile: {},
  retailerSalesAnalysis: {},
  retailersUnmaskedData: {},
  retailerPreviousSalesperson: {
    next: null,
    total_count: "",
    results: [],
  },
  visitDetails: {
    next: null,
    total_count: "",
    results: [],
  },
};

export default function retailerReducer(
  state: IRetailerState = inititalState,
  action: IRetailerActionType
) {
  switch (action.type) {
    case MY_RETAILERS_LIST:
      return { ...state, retailersList: action.data };
    case MY_RETAILERS_PROFILE:
      return { ...state, retailersProfile: action.data };
    case GET_RETAILER_SALES_ANALYSIS:
      return { ...state, retailerSalesAnalysis: action.data };
    case GET_RETAILER_PREVIOUS_SALESPERSON:
      let bdeData = action.data;
      if (state.retailerPreviousSalesperson) {
        bdeData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [
                ...state.retailerPreviousSalesperson?.results,
                ...action.data?.results,
              ]
            : [...action.data?.results],
        };
      }
      return { ...state, retailerPreviousSalesperson: bdeData };
    // return {
    //   ...state,
    //   retailerPreviousSalesperson: {
    //     next: action.data?.next,
    //     total_count: action.data?.total_count,
    //     results: [
    //       ...state.retailerPreviousSalesperson.results,
    //       ...action.data?.results,
    //     ],
    //   },
    // };
    case GET_VISIT_HISTORY_DETAILS:
      let visitData = action.data;
      if (state.visitDetails) {
        visitData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.visitDetails?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, visitDetails: visitData };

    // return {
    //   ...state,
    //   visitDetails: {
    //     next: action.data?.next,
    //     total_count: action.data?.total_count,
    //     results: [...state.visitDetails.results, ...action.data?.results],
    //   },
    // };
    case GET_RETAILER_UNMASKED_DATA:
      return { ...state, retailersUnmaskedData: action.data };
    default:
      return state;
  }
}
