import {
  ManagerActionTypes,
  GET_ASM_MANAGER_LIST,
  GET_RM_MANAGER_LIST,
  GET_CSM_MANAGER_LIST,
} from "store/constants/managers";

// Initial States

// TODO - Have to define interface for managers initial states
const initialState = {
  asm: {
    isLoading: false,
    data: [],
  },
  rm: {
    isLoading: false,
    data: [],
  },
  csm: {
    isLoading: false,
    data: [],
  },
};

export default function managerReducer(
  state: any = initialState,
  action: ManagerActionTypes
) {
  // Assigning data to respective states using switching action_types
  switch (action.type) {
    // Area Sales Manager List
    case GET_ASM_MANAGER_LIST:
      let asmData = action.data;
      if (state.asm.data) {
        asmData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          page_size: action.data?.page_size,
          results: action.data?.previous
            ? [...state.asm.data?.results, ...action.data?.results]
            : action.data?.results,
        };
      }

      return {
        ...state,
        asm: { isLoading: action.loading, data: asmData },
      };

      // CSM Manager List
      case GET_CSM_MANAGER_LIST:
      let csmData = action.data;
      if (state.csm.data) {
        csmData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          page_size: action.data?.page_size,
          results: action.data?.previous
            ? [...state.csm.data?.results, ...action.data?.results]
            : action.data?.results,
        };
      }

      return {
        ...state,
        csm: { isLoading: action.loading, data: csmData },
      };



    // Regional Sales Manager List
    case GET_RM_MANAGER_LIST:
      return {
        ...state,
        rm: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Returning all states by defaults
    default:
      return { ...state };
  }
}
