export const GET_PINCODE = "GET_PINCODE";
export const GET_ASSIGNED_PINCODE = "GET_ASSIGNED_PINCODE";
export const GET_PINCODE_LOADING = "GET_PINCODE_LOADING";
export const GET_AREAS = "GET_AREAS";
export const GET_REGIONS = "GET_REGIONS";
export const GET_STATES = "GET_STATES";
export const GET_CITIES = "GET_CITIES";
export const GET_MY_ASM_AREAS = "GET_MY_ASM_AREAS";
export const PINCODE_LIST = "PINCODE_LIST";
export const ACTIVE_PINCODE_LIST = "ACTIVE_PINCODE_LIST";
export const UPLOAD_PINCODE = "UPLOAD_PINCODE";
export const GET_REGION_DATA = "GET_REGION_DATA";
