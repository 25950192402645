import {
  ASM_CREATED,
  MY_ASM_LIST,
  ASSIGNED_PINCODES_RM,
  ASSIGNED_AREA,
  ASSIGNED_REGIONS,
  DELETE_PINCODES,
  IS_ALL_PINCODES_SELECTED,
  ASM_PERFORMANCE,
  FETCH_ASM_PROFILE,
  DELETE_CITIES,
  IS_LOADING,
  ASM_LIST_LOADING,
  GET_TOP_ASM_LIST,
  FETCH_ASM_STATS,
  FETCH_ASM_STATS_V2,
  MY_ASM_MANAGER_LIST,
  CSM_AND_ASM_LIST,
  CSM_AND_ASM_LIST_LOADING,
  GET_TOP_CSM_LIST,
  CSM_PERFORMANCE,
  FETCH_CSM_PROFILE,
  GET_CSM_LINKED_ASM,
  DELETE_ASM,
  ASSIGNED_ASM_RM,
  CSM_CREATED,
} from "store/constants/RegionalManager";
import { LOCATION_CHANGE } from "store/constants/changeLocation";

import { uniqPinCodes, uniqueArray } from "utils/array";
import { IRegionalManagerState, RMActionType } from "types/rmTypes";
import CSMPerformance from "components/CSMProfile/CSMPerformance";
import { assignedAsm } from "store/actions/RegionalManager/addNewASM";

const inititalState = {
  newASM: null,
  newCSM: null,
  myASMManager: { results: [] },
  myAsmList: { results: [] },
  topASMList: {
    isLoading: false,
    data: [],
  },
  fetchAsmStats: {
    isLoading: false,
    data: {},
  },
  fetchAsmStatsV2: {
    isLoading: false,
    data: {},
  },
  assignedPincodes: [],
  assignedAdditionalPincode: [],
  assignedRegions: "",
  assignedArea: "",
  assignedStatesAndCities: [],
  removedPincodes: [],
  isAllSelected: false,
  asmPerformance: {},
  csmPerformance: {},
  asmProfile: {},
  csmProfile: {},
  asmDashboardPerformance: null,
  topBDEPerformance: null,
  topSellingSKU: null,
  brandsInFocus: null,
  is_loading: true,
  asm_list_loading: false,
  csm_and_asm_list_loading: false,
  csmAndAsmList: { results: [] },
  csmLinkedAsm: {
    data: null,
    isLoading: false,
  },
  topCSMList: {
    isLoading: false,
    data: [],
  },
  assignedAsm: [],
  removedAsm: [],
};

export default function regionalManagerReducer(
  state: IRegionalManagerState = inititalState,
  action: RMActionType
) {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, is_loading: action.data };

    case MY_ASM_MANAGER_LIST:
      let asmData = action.data;
      if (state.myASMManager) {
        asmData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.myASMManager?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, myASMManager: asmData };

    case ASM_LIST_LOADING:
      return { ...state, asm_list_loading: action.data };

    case ASM_CREATED:
      return { ...state, newASM: action.data };

    case CSM_CREATED:
      return { ...state, newCSM: action.data };

    case ASM_PERFORMANCE:
      return { ...state, asmPerformance: action.data };

    case MY_ASM_LIST:
      return { ...state, myAsmList: action.data };

    case GET_TOP_ASM_LIST:
      return {
        ...state,
        topASMList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case CSM_PERFORMANCE:
      return { ...state, csmPerformance: action.data };

    case CSM_AND_ASM_LIST_LOADING:
      return { ...state, csm_and_asm_list_loading: action.data };

    case CSM_AND_ASM_LIST:
      return { ...state, csmAndAsmList: action.data };

    case GET_TOP_CSM_LIST:
      return {
        ...state,
        topCSMList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case FETCH_CSM_PROFILE:
      return { ...state, csmProfile: action.data };

    case GET_CSM_LINKED_ASM:
      return {
        ...state,
        csmLinkedAsm: { isLoading: action.loading, data: action?.data },
      };

    case FETCH_ASM_STATS:
      return {
        ...state,
        fetchAsmStats: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case FETCH_ASM_STATS_V2:
      return {
        ...state,
        fetchAsmStatsV2: {
          isLoading: action.loading,
          data: action.data,
        },
      };
    case ASSIGNED_REGIONS:
      return { ...state, assignedRegions: action.data };

    case ASSIGNED_AREA:
      return { ...state, assignedArea: action.data };

    case FETCH_ASM_PROFILE:
      return { ...state, asmProfile: action.data };

    case ASSIGNED_PINCODES_RM:
      // Total Assigned Pincodes
      let newSelected = [...state.assignedPincodes];

      if (Array.isArray(action.data)) {
        newSelected.push(...action.data);
      } else {
        newSelected.push(action.data);
      }

      const uniqNewSelected = uniqPinCodes(newSelected);

      // Only assigned pincode slected by one by one
      let newAddPincodes =
        state.assignedAdditionalPincode !== undefined
          ? [...state.assignedAdditionalPincode]
          : [];
      newAddPincodes.push(action.data?.slug);
      const removeUndefined = newAddPincodes.filter(
        (item) => item !== undefined
      );
      const uniqNewAddPincodes = uniqueArray(removeUndefined);

      // Removed selected data from the exculded array
      let removeFromExcluded = state.removedPincodes.filter(
        (item: any) => item !== action.data.slug
      );

      return {
        ...state,
        assignedPincodes: uniqNewSelected,
        assignedAdditionalPincode: uniqNewAddPincodes,
        removedPincodes: removeFromExcluded,
      };

    case DELETE_PINCODES:
      const filterData = state.assignedPincodes.filter(
        (item: any) => item.slug !== action.data
      );

      // store removed pincodes slug
      let rmvPincodes: string[] = [...state.removedPincodes];
      rmvPincodes.push(action.data);

      return {
        ...state,
        assignedPincodes: filterData,
        removedPincodes: rmvPincodes,
      };

    case ASSIGNED_ASM_RM:
      // Total Assigned ASM
      let newSelectedAsm = [];

      if (Array.isArray(action.data)) {
        newSelectedAsm.push(...action.data);
      } else {
        newSelectedAsm.push(action.data);
      }

      const uniqNewSelectedAsm = uniqueArray(newSelectedAsm);

      // Removed selected data from the exculded array
      let removeFromExcludedAsm = state.removedAsm.filter(
        (item: any) => !action.data?.includes(item)
      );

      return {
        ...state,
        assignedAsm: uniqNewSelectedAsm,
        removedAsm: removeFromExcludedAsm,
      };

    case DELETE_ASM:
      const filterAsmData = state.assignedAsm.filter(
        (item: any) => item.username !== action.data
      );

      // store removed asm slug
      let rmvAsm: string[] = [...state.removedAsm];
      rmvAsm.push(action.data);

      return {
        ...state,
        assignedAsm: filterAsmData,
        removedAsm: rmvAsm,
      };

    case DELETE_CITIES:
      const filter = state.assignedStatesAndCities.filter(
        (item: any) => item.city !== action.data
      );

      return {
        ...state,
        assignedStatesAndCities: filter,
      };

    case IS_ALL_PINCODES_SELECTED:
      let isAllSelectedPincodes: any[] = [...state.assignedPincodes];
      let isAllSelectedStatesAndCities: any[] = [
        ...state.assignedStatesAndCities,
      ];

      if (action.data.checked) {
        const slugs = action.data.pincodes?.results?.map(
          (item: any) => item.slug
        );
        let removed = isAllSelectedPincodes.filter(
          (item) => !slugs.includes(item.slug)
        );
        isAllSelectedPincodes = removed;

        // isAllSelectedPincodes.push(...action.data.pincodes.results);
        isAllSelectedStatesAndCities.push(action.data.selectedStatesAndCities);
      } else {
        // const slugs = action.data.pincodes.map((item: any) => item.slug);
        // let removed = isAllSelectedPincodes.filter(
        //   (item) => !slugs.includes(item.slug)
        // );
        // isAllSelectedPincodes = removed;

        // Remove cities after unchecked
        const cityslug = state.assignedStatesAndCities.map(
          (item: any) => item.city
        );
        let removedCity = isAllSelectedStatesAndCities.filter(
          (item) => !cityslug.includes(item.city)
        );
        isAllSelectedStatesAndCities = removedCity;
      }

      // const unqPincodes = uniqPinCodes(isAllSelectedPincodes);

      return {
        ...state,
        isAllSelected: action.data.checked,
        // assignedPincodes: unqPincodes,
        assignedStatesAndCities: isAllSelectedStatesAndCities,
      };

    case LOCATION_CHANGE:
      return inititalState;

    default:
      return state;
  }
}
