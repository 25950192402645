/* =============================
  ASM Manager List
 =============================== */
export const GET_ASM_MANAGER_LIST = "GET_ASM_MANAGER_LIST";
export interface IGetASMManagerActionType {
  type: typeof GET_ASM_MANAGER_LIST;
  data?: any;
  loading?: boolean;
}

/* =============================
  CSM Manager List
 =============================== */
 export const GET_CSM_MANAGER_LIST = "GET_CSM_MANAGER_LIST";
 export interface IGetCSMManagerActionType {
   type: typeof GET_CSM_MANAGER_LIST;
   data?: any;
   loading?: boolean;
 }

/* =============================
  RM Manager List
 =============================== */

export const GET_RM_MANAGER_LIST = "GET_RM_MANAGER_LIST";
export interface IGetRMManagerActionType {
  type: typeof GET_RM_MANAGER_LIST;
  data?: any;
  loading?: boolean;
}

export type ManagerActionTypes =
  | IGetASMManagerActionType
  | IGetRMManagerActionType
  | IGetCSMManagerActionType
