import React, { lazy, useEffect, useState } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import axiosInterceptorHandler from "api/axiosInstance";
import theme from "./theme";
import SuspenseLoader from "components/generic/Loader";
import GlobalStyles from "styles/globalStyles";

import { ErrorBoundary } from "react-error-boundary";
import axiosInterceptorHandlerV2 from "api/axiosInstanceV2";
import AppRouter from "AppRouter";
import axios from "api/axios";
import { featureSwitchURL } from "api/FeatureSwitch";
import { getUserURL } from "api/auth";
import lazyRetry from "routes/lazyRetry";
import { useRoutes } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { getUser, getAccessURLsOfCurrentUser, getPermissionsOfCurrentUser } from "store/actions/auth";
import routes from "./routes";

import CheckPermissionAccess from "components/auth/checkPermissionAccess";

import { getFeatureSwitchList } from "store/actions/FeatureSwitch";

//components
const Login = lazy(() => lazyRetry(() => import("shared/Authentication")));

export default function Appv2() {

  //dispatch
  const dispatch = useDispatch();

  //state variables
  const [routeConfigLoading, setRouteConfigLoading] = useState<boolean>();
  const [routeConfigV2, setRouteConfigV2] = useState<boolean>();

  // axios 
  axiosInterceptorHandler();
  axiosInterceptorHandlerV2();

  // // app v1 configuration
  const isAuthenticated = useSelector(
    (state: RootStateOrAny) => state.auth.isAuthenticated
  );
  const authUser = useSelector((state: RootStateOrAny) => state.auth.user);
  const userLoading = useSelector((state: RootStateOrAny) => state.auth.userLoading);
  const has_permission = useSelector(
    (state: RootStateOrAny) => state.auth.has_permission
  );

  const renderContent = useRoutes(routes(isAuthenticated, authUser));

  // Multiple requests to get user_profile have been removed
  useEffect(() => {
    if (!authUser && isAuthenticated) {
      dispatch(getUser());
      dispatch(getFeatureSwitchList());
      dispatch(getPermissionsOfCurrentUser());
      dispatch(getAccessURLsOfCurrentUser());
    }
  }, []);



  // use effect to fetch feature switch details (route configuration)
  useEffect(() => {
    const checkIfRouteConfigV2Enabled = async () => {
      setRouteConfigLoading(true);
      await axios.get(featureSwitchURL).then((response: any) => {
        setRouteConfigLoading(false);
        if (response.status === 200) {
          if (response.data?.status_code === 200) {
            const featureList = response?.data?.data ? response?.data?.data : undefined;
            const isWebNavigationConfigV2 = featureList?.some((feature: any) => {
              return feature?.name === 'web-navigation-config-v2' && feature?.enabled;
            });
            setRouteConfigV2(isWebNavigationConfigV2);
          }
        }
      }).catch((error) => {
        setRouteConfigLoading(false);
        console.error(error);
        setRouteConfigV2(false);
      });
    }

    if (authUser && !routeConfigLoading)
      checkIfRouteConfigV2Enabled();
    else if (authUser === null) {
      setRouteConfigLoading(false);
      setRouteConfigV2(true);
    }
  }, [authUser]);


  // getcontent
  const getContent = () => {

    // loader
    if (userLoading || routeConfigLoading) return <SuspenseLoader />;

    // if route config v2 is disabled
    else if (routeConfigV2 === false) {
      console.log("Router config : Old Router Rendered");
      return renderContent;
    }

    // if route config v2 is enabled
    else if (routeConfigV2 === true) {
      console.log("Router config : New Router Rendered");
      return <AppRouter user={authUser} isAuthenticated={isAuthenticated} setRouteConfigV2={setRouteConfigV2} />
    }

    //else
    else return null;

  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <React.Suspense fallback={<SuspenseLoader />}>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            {getContent()}
            {!has_permission && <CheckPermissionAccess />}
          </ErrorBoundary>
        </React.Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
