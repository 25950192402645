import {
  CHActionType,
  GET_STATS,
  GET_STATS_V2,
  GET_TOP_RM_LIST,
  GET_CHART_DATA_BY_CATEGORY,
  GET_CHART_DATA_BY_ORDER,
  GET_RM_LIST,
  GET_RM_PROFILE,
  GET_RM_LINKED_ASM,
  GET_RM_STATS,
  GET_RM_LINKED_CSM,
} from "store/constants/CountryHead";

interface ICountryHeadStates {
  stats: any | null;
  topRMList: any | null;
  chartDataByCategory: any | null;
  chartDataByOrder: any | null;
  rmList: any | null;
  rmProfile: any | null;
  rmLinkedASM: any | null;
  rmStats: any | null;
}

const initialStates = {
  stats: {
    data: null,
    isLoading: false,
  },
  statsV2: {
    data: null,
    isLoading: false,
  },
  topRMList: {
    data: null,
    isLoading: false,
  },
  chartDataByCategory: {
    data: null,
    isLoading: false,
  },
  chartDataByOrder: {
    data: null,
    isLoading: false,
  },
  rmList: {
    results: null,
    isLoading: false,
  },
  rmProfile: null,
  rmLinkedASM: {
    data: null,
    isLoading: false,
  },
  rmLinkedCSM: {
    data: null,
    isLoading: false,
  },
  rmStats: {
    data: null,
    isLoading: false,
  },
};

export default function countryHeadReducer(
  state: ICountryHeadStates = initialStates,
  action: CHActionType
) {
  switch (action.type) {
    case GET_STATS:
      return {
        ...state,
        stats: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_STATS_V2:
      return {
        ...state,
        statsV2: {
          data: action.data,
          isLoading: action.loading
        }
      }

    case GET_TOP_RM_LIST:
      return {
        ...state,
        topRMList: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_CHART_DATA_BY_CATEGORY:
      return {
        ...state,
        chartDataByCategory: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_CHART_DATA_BY_ORDER:
      return {
        ...state,
        chartDataByOrder: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_RM_LIST:
      return {
        ...state,
        rmList: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_RM_PROFILE:
      return { ...state, rmProfile: action.data };

    case GET_RM_STATS:
      return {
        ...state,
        rmStats: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_RM_LINKED_ASM:
      return {
        ...state,
        rmLinkedASM: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_RM_LINKED_CSM:
      return {
        ...state,
        rmLinkedCSM: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    default:
      return state;
  }
}
