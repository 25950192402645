import axios from "api/axios";
import {
  USER_LOGIN_SUCCESS,
  AUTHENTICATION_FAILED,
  GET_USER_DETAILS,
  LOGOUT_SUCCESSFULL,
  HAS_PERMISSION,
  GET_ACCESS_URLS_OF_CURRENT_USERS,
  GET_PERMISSIONS_OF_CURRENT_USER,
} from "store/constants/auth";

import {
  authURL,
  getUserURL,
  logOutURL,
  impersonatingURL,
  checkAccessURLsPermissionURL,
} from "api/auth";
import { message } from "components/generic/FosAlert";
import cookies from "utils/cookies";
import store, { RootState } from "store";

interface User {
  token: string;
  exp?: number;
}

export const loginUserSuccess = (user: User) => (dispatch: any) => {
  dispatch({ type: USER_LOGIN_SUCCESS, data: user });
  window.location.reload();
};

export const signIn =
  (data: any, setLoading?: any) => async (dispatch: any) => {
    if (data) {
      try {
        const response = await axios.post(authURL, data);
        if (response.status === 200) {
          if (response.data?.status_code === 200) {
            dispatch(loginUserSuccess(response.data.data));
          }

          if (response.data?.status_code === 400) {
            message.error(response.data.message);
            setLoading(false);
          }
          if (response.data?.status_code === 403) {
            message.error(
              response.data.message,
              "Please use only Nykaa authorized email."
            );
            setLoading(false);
          }
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 403) {
          message.error(
            "You don't have access",
            "Please use only Nykaa authorized email."
          );
        }
        dispatch({ type: AUTHENTICATION_FAILED });
        throw error;
      }
    }
  };

export const logOut = () => async (dispatch: any) => {
  try {
    const response = await axios.get(logOutURL);
    if (response.status === 200) {
      dispatch({ type: LOGOUT_SUCCESSFULL });
      window.location.reload();
    }
  } catch (error) {
    throw error;
  }
};

export const getUser = () => async (dispatch: any) => {
  let IMPERSONATE_ID = cookies.get("SECURE-IMPSID-NS");
  dispatch({ type: GET_USER_DETAILS, data: null, loading: true });
  try {
    const response = await axios.get(getUserURL);
    if (response.status === 200) {
      if (response.data?.status_code === 200) {
        dispatch({
          type: GET_USER_DETAILS,
          data: response.data.data,
          loading: false,
        });
        if (
          response.data.data?.user_role === "country_head" &&
          IMPERSONATE_ID === undefined
        ) {
          dispatch(impersonateUser());
        } else if (response.data.data?.user_role !== "country_head") {
          cookies.delete("SECURE-IMPSID-NS");
        }
      } else if (response.data?.status_code === 401) {
        dispatch({
          type: GET_USER_DETAILS,
          data: null,
          loading: false,
        });
        cookies.delete("SECURE-TOKEN");
        window.location.reload();
      }
    }
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS,
      data: null,
      loading: false,
    });
    throw error;
  }
};

export const impersonateUser = () => async (dispatch: any) => {
  try {
    const response = await axios.get(impersonatingURL);
    if (response.status === 200) {
      if (response.data?.status_code === 200) {
        cookies.set("SECURE-IMPSID-NS", response.data.data?.impersonating_id);
        window.location.reload();
      }
      if (response.data?.status_code === 400) {
      }
    }
  } catch (error) {
    throw error;
  }
};

export const checkPermission = (data: boolean) => (dispatch: any) => {
  dispatch({ type: HAS_PERMISSION, data: data });
};

export const getAccessURLsOfCurrentUser = () => async (dispatch: any) => {
  try {
    const response = await axios.get(checkAccessURLsPermissionURL);
    if (response.status === 200) {
      if (response.data?.status_code === 200) {
        dispatch({
          type: GET_ACCESS_URLS_OF_CURRENT_USERS,
          data: response.data.data,
        });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getPermissionsOfCurrentUser = () => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_PERMISSIONS_OF_CURRENT_USER,
      loading: true,
    });
    const response = await axios.get(checkAccessURLsPermissionURL);
    if (response.status === 200) {
      if (response.data?.status_code === 200) {
        dispatch({
          type: GET_PERMISSIONS_OF_CURRENT_USER,
          data: response.data.data,
          loading: false,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: GET_PERMISSIONS_OF_CURRENT_USER,
      data: [],
      loading: false,
    });
  }
};

export const isPermissionEnabled = (
  getState: () => RootState,
  key: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const unsubscribe = store.subscribe(() => {
      const { auth } = getState();
      if (auth?.permissions?.isLoading === false) {
        const isPermissionEnabled = auth?.permissions?.data?.some(
          (permission: any) => permission?.name === key
        );
        unsubscribe();
        clearTimeout(timeoutId);
        resolve(isPermissionEnabled);
      }
    });

    const timeoutId = setTimeout(() => {
      unsubscribe();
      reject(new Error("Timeout: Fetching permission took too long."));
    }, 10000);
  });
};

export default {
  login: signIn,
  logOut,
  getUser,
  checkPermission,
  getAccessURLsOfCurrentUser,
};
