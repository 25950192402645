import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertTitle, Typography, CircularProgress } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, "direction">;

function Transition(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

export let message: any;

function FosAlert() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [severity, setSeverity] = useState<any>("success");
  const [content, setContent] = useState<any>("");

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const helper = (title: string, content?: any, severity?: any) => {
    setOpen(true);
    setTitle(title);
    setContent(content);
    setSeverity(severity);
  };
  message = {
    success: (title: string, content?: any) => {
      helper(title, content, "success");
    },
    error: (title: string, content?: any) => {
      helper(title, content, "error");
    },
    info: (title: string, content?: any) => {
      helper(title, content, "info");
    },
    warning: (title: string, content?: any) => {
      helper(title, content, "warning");
    },
  };
  return (
    <Snackbar
      sx={{ mt: 7 }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Alert onClose={() => setOpen(false)} severity={severity} icon={severity === "info" && title.includes("Retrying")?  <CircularProgress size={18} color="inherit"  disableShrink /> : null}>
        <AlertTitle sx={{ fontSize: "0.895rem" }}>{title}</AlertTitle>
        <Typography sx={{ fontSize: "12px", fontStyle: "italic" }}>
          {content}
        </Typography>
      </Alert>
    </Snackbar>
  );
}
export default FosAlert;
