// constant and actionTypes
import {
  PincodeActionTypes,
  PINCODE_LIST,
} from "store/constants/PincodeManagement";

// initial states

// TODO - have to define interface for pincode initial states
const initialState = {
  pincodeList: {
    isLoading: false,
    data: null,
  },
};

export default function pincodeReducer(
  state: any = initialState,
  action: PincodeActionTypes
) {
  // assigning data to respective states using switching action_types

  switch (action.type) {
    // Pincode List
    case PINCODE_LIST:
      return {
        ...state,
        pincodeList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Returning all states by default
    default:
      return { ...state };
  }
}
