// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = <T>(componentImport: () => Promise<T>): Promise<T> => {
  return new Promise((resolve, reject) => {
    // Check if the window has already been refreshed

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("dsh-retry-lazy-refreshed") || "false"
    );

    // try to import the component

    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem("dsh-retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("dsh-retry-lazy-refreshed", "true");
        }

        reject(error); //Default error behaviour as already tried refresh
      });
  });
};

// const lazyRetry = <T>(component: () => Promise<T>, maxRetry = 3): Promise<T> => {
//   return new Promise((resolve, reject) => {
//     component()
//       .then(resolve)
//       .catch((error) => {
//         if(maxRetry === 0) {
//           reject(error);
//           // this is probably a white page error. reload the main page

//           window.location.reload();
//           return;
//         }

//         lazyRetry(component, --maxRetry).then(resolve, reject);
//       })
//   })
// }

export default lazyRetry;
