// constant and actionTypes
import { NDRActionTypes, NDR_LIST, NDR_DETAIL } from "store/constants/NDR";

// initial states

// TODO - have to define interface for feedback initial states
const initialState = {
  ndrList: {
    isLoading: false,
    data: null,
  },
  ndrDetail: {
    isLoading: false,
    data: null,
  },
};

export default function ndrReducer(
  state: any = initialState,
  action: NDRActionTypes
) {
  // assigning data to respective states using switching action_types

  switch (action.type) {
    // NDR List
    case NDR_LIST:
      return {
        ...state,
        ndrList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // NDR Details
    case NDR_DETAIL:
      return {
        ...state,
        ndrDetail: {
          isLoading: action.data,
          data: action.data,
        },
      };

    // Returning all states by default
    default:
      return { ...state };
  }
}
