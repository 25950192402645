// Visit History constants

/* =============================
  Visit History StatsCard Data
 =============================== */
export const GET_STATS = "GET_STATS";

export interface IGetStatsActionType {
  type: typeof GET_STATS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Visit History List Data
 =============================== */
export const GET_LIST = "GET_LIST";

export interface IGetListActionType {
  type: typeof GET_LIST;
  data?: any;
  loading?: boolean;
}

/* =============================
  Visit History Details Data
 =============================== */
export const GET_DETAILS = "GET_DETAILS";

export interface IGetDetailsActionType {
  type: typeof GET_DETAILS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Visit History Details List Data
 =============================== */
export const GET_DETAILS_LIST = "GET_DETAILS_LIST";

export interface IDetailsListActionTypes {
  type: typeof GET_DETAILS_LIST;
  data?: any;
  loading?: boolean;
}

/* =============================
  Visit History Details Store Onboard Retailer List Data
 =============================== */
export const GET_STORE_ONBOARD_RETAILER_LIST =
  "GET_STORE_ONBOARD_RETAILER_LIST";

export interface IGetStoreOnboardRetailerTypes {
  type: typeof GET_STORE_ONBOARD_RETAILER_LIST;
  data?: any;
  loading?: boolean;
}
/* =============================
  Visit History Details Lead Saved Retailer List Data
 =============================== */
export const GET_LEAD_SAVED_RETAILER_LIST = "GET_LEAD_SAVED_RETAILER_LIST";

export interface IGetLeadSavedRetailerTypes {
  type: typeof GET_LEAD_SAVED_RETAILER_LIST;
  data?: any;
  loading?: boolean;
}

/* =============================
Download Report
 =============================== */
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export interface IDownloadReportActionTypes {
  type: typeof DOWNLOAD_REPORT;
  data: any;
}

export type ReportActionTypes = IDownloadReportActionTypes;

export type GetStatsActionTypes = IGetStatsActionType;

export type GetListActionTypes = IGetListActionType;

export type GetDetailsActionTypes = IGetDetailsActionType;

export type GetDetailsListActionTypes =
  | IDetailsListActionTypes
  | IGetStoreOnboardRetailerTypes
  | IGetLeadSavedRetailerTypes;

export type VisitHistoryActionTypes =
  | GetStatsActionTypes
  | GetListActionTypes
  | GetDetailsActionTypes
  | GetDetailsListActionTypes
  | ReportActionTypes;
