import {
  ASM_DASHBOARD_PERFORMANCE,
  ASM_DASHBOARD_PERFORMANCE_V2,
  TOP_BDE_PERFORMANCE,
  TOP_RETAILERS_PERFORMANCE,
  TOP_SELLING_SKU,
  BRANDS_IN_FOCUS,
  ASM_DASHBOARD_LOADING,
  ASM_TABLE_LOADING,
  ASMActionType,
} from "store/constants/ASMDashboard";
import { IRegionalManagerState } from "types/asmTypes";

const inititalState = {
  asmDashboardPerformance: {},
  asmDashboardPerformanceV2: {},
  topBDEPerformance: [],
  topRetailersPerformance: [],
  topSellingSKU: null,
  brandsInFocus: null,
  asm_loading: false,
  asm_table_loading: false,
};

export default function asmDashboardReducer(
  state: IRegionalManagerState = inititalState,
  action: ASMActionType
) {
  switch (action.type) {
    case ASM_DASHBOARD_PERFORMANCE:
      return { ...state, asmDashboardPerformance: action.data };

    case ASM_DASHBOARD_PERFORMANCE_V2:
      return { ...state, asmDashboardPerformanceV2: {
        isLoading: action.loading,
        data: action.data,
      } };

    case TOP_BDE_PERFORMANCE:
      return { ...state, topBDEPerformance: action.data };

    case TOP_RETAILERS_PERFORMANCE:
      return { ...state, topRetailersPerformance: action.data };

    case TOP_SELLING_SKU:
      return { ...state, topSellingSKU: action.data };

    case BRANDS_IN_FOCUS:
      return { ...state, brandsInFocus: action.data };

    case ASM_DASHBOARD_LOADING:
      return { ...state, asm_loading: action.data };

    case ASM_TABLE_LOADING:
      return { ...state, asm_table_loading: action.data };

    default:
      return state;
  }
}
