import customAxios from "./axios";
import axios, { AxiosResponse, AxiosError } from "axios";
import store from "store";
import auth from "store/actions/auth";
import { message } from "components/generic/FosAlert";
import cookies from "utils/cookies";
import { LOGOUT_SUCCESSFULL } from "store/constants/auth";

interface ApiResponse<T = any> {
  status_code: number;
  message?: string;
  data?: T;
}

const MAX_RETRY_ATTEMPTS = 1; // Maximum number of retry attempts

function handle4xxErrors(
  response: AxiosResponse<ApiResponse>
): Promise<AxiosError<ApiResponse>> {
  const { status_code, message: errorMessage } = response.data;

  if (status_code === 404) {
    message.error("404: Page not found.");
  } else if (status_code >= 405 && status_code <= 499) {
    message.error(
      `${status_code} - ${errorMessage}`,
      "There is a problem with your request. Please check your input and try again."
    );
  } else if (status_code >= 500) {
    message.error(
      `${status_code} - ${errorMessage}`,
      "Internal Server Error. There is a problem with the server."
    );
  }

  return Promise.reject(response);
}

export default function axiosInterceptorHandler() {
  const retry = async (requestConfig: any): Promise<AxiosResponse> => {
    message.info("Request canceled. Retrying for a seamless experience.");

    // Create a new cancel token for the retry request
    const cancelToken = axios.CancelToken.source();

    //  Add cancel token to the request config
    requestConfig.cancelToken = cancelToken.token;

    // Delay before retrying (you can customize this delay)
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Retry the request with the new cancel token
    return customAxios.request(requestConfig);
  };

  customAxios.interceptors.response.use(
    (response: any) => {
      if (response?.status === 200) {
        const { status_code } = response.data;

        if (status_code >= 405 && status_code <= 499) {
          return handle4xxErrors(response);
        }

        // Token expired or invalid
        if (status_code === 401) {
          message.error("401: Unauthorized. Please log in again."); // Customize for 401
          cookies.delete("SECURE-TOKEN");
          store.dispatch<any>({ type: LOGOUT_SUCCESSFULL });
        }

        return response;
      } else if (response?.status === 201) {
        message.success("201: Resource created successfully."); // Customize for 201
        return response;
      } else if (response?.status === 204) {
        message.success("204: No Content."); // Customize for 204
        return response;
      } else if (response?.status === 400) {
        message.error(
          "400: Bad Request. Please check your request parameters."
        ); // Customize for 400
        return Promise.reject(response);
      } else if (response?.status >= 500) {
        message.error(
          "Network Error",
          "There is a problem with your request. Please check your network connection and try again."
        );
        return Promise.reject(response);
      } else {
        message.error(
          "Error: Timeout",
          "We encountered a temporary network issue. Please try again in a moment."
        );
        return Promise.reject(response);
      }
    },
    (error: any) => {
      const originalRequest = error?.config;

      if (!error.response) {
        if (error?.data?.status_code === 401) {
          // Customize for 401
          message.error("401: Unauthorized. Please log in again.");
          cookies.delete("SECURE-TOKEN");
          store.dispatch<any>({ type: LOGOUT_SUCCESSFULL });
        } else if (error?.data?.status_code === 403) {
          message.error(
            "403: Forbidden. You don't have permission for this action."
          ); // Customize for 403
          store.dispatch<any>(auth.checkPermission(false));
        }
      } else if (error.response.status === 401) {
        message.error("401: Unauthorized. Please log in again.");
        cookies.delete("SECURE-TOKEN");
        store.dispatch<any>({ type: LOGOUT_SUCCESSFULL });
      } else if (error.response.status === 403) {
        store.dispatch<any>({ type: LOGOUT_SUCCESSFULL });
      } else if (error?.response?.status === 404) {
        // Do nothing for 404 errors
      } else if (error?.response?.status === 500) {
        message.error(
          "500 - Internal Server Error",
          "There is a problem with the resource you are looking for, and it cannot be displayed."
        );
        originalRequest._retry = originalRequest._retry || 0;

        if (originalRequest._retry < MAX_RETRY_ATTEMPTS) {
          originalRequest._retry++;
          return retry(originalRequest);
        }
      }

      if (
        error?.code === "ECONNABORTED" &&
        error?.message?.includes("timeout")
      ) {
        message.error(
          "We encountered a temporary network issue. Please try again in a moment."
        );
        originalRequest._retry = originalRequest._retry || 0;

        if (originalRequest._retry < MAX_RETRY_ATTEMPTS) {
          originalRequest._retry++;
          return retry(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );
}
