export const NEW_BDE = "NEW_BDE";
export const MY_BDE = "MY_BDE";
export const ASSIGNED_RETAILERS = "ASSIGNED_RETAILERS";
export const MANAGER_SALEPERSON_LIST = "MANAGER_SALEPERSON_LIST";
export const ASSIGNED_RETAILER = "ASSIGNED_RETAILER";
export const ASSIGNED_AREAS = "ASSIGNED_AREAS";
export const ASSIGNED_PINCODES = "ASSIGNED_PINCODES";
export const DELETE_RETAILER = "DELETE_RETAILER";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_PINCODE = "DELETE_PINCODE";
export const DELETE_PINCODE_AND_CATEGORY = "DELETE_PINCODE_AND_CATEGORY";
export const BDE_LIST = "BDE_LIST";
export const BDE_AREAS = "BDE_AREAS";
export const BDE_PINCODES = "BDE_PINCODES";
export const BDE_SEGMENTS = "BDE_SEGMENTS";
export const IS_ALL_SELECTED = "IS_ALL_SELECTED";
export const RETAILERS_LIST = "RETAILERS_LIST";
export const ASSIGNED_RETAILER_LIST = "ASSIGNED_RETAILER_LIST";
export const RETAILERS_LIST_LOADING = "RETAILERS_LIST_LOADING";
export const GET_BDE_LIST = "GET_BDE_LIST";
export const GET_BDE_PROFILE = "GET_BDE_PROFILE";
export const GET_LINKED_RETAILER = "GET_LINKED_RETAILER";
export const GET_BLOOD_GROUP_OPTIONS = "GET_BLOOD_GROUP_OPTIONS";
export const GET_BDE_PERFORMANCE = "GET_BDE_PERFORMANCE";
export const GET_UNMASKED_DATA = "GET_UNMASKED_DATA";
export const BDE_LOADING = "BDE_LIST_LOADING";
export const GET_ASSIGNED_RETAILERS_OF_PARTICULAR_BDE =
  "GET_ASSIGNED_RETAILERS_OF_PARTICULAR_BDE";

// Check Location Change

export const LOCATION_CHANGE = "LOCATION_CHANGE";
