import {
  GET_CHART_DATA_BY_ORDER,
  GET_CHART_DATA_BY_CATEGORY,
} from "store/constants/chart";
import { ChartActionType, IChartStates } from "types/chart";

const initialStates = {
  chartDataByOrder: {
    isLoading: false,
    data: [],
  },
  chartDataByCategory: {
    isLoading: false,
    data: [],
  },
};

export default function chartReducer(
  state: IChartStates = initialStates,
  action: ChartActionType
) {
  switch (action.type) {
    case GET_CHART_DATA_BY_ORDER:
      return {
        ...state,
        chartDataByOrder: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_CHART_DATA_BY_CATEGORY:
      return {
        ...state,
        chartDataByCategory: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    default:
      return state;
  }
}
