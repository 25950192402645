import { lazy } from "react";
import { Navigate } from "react-router-dom";
import lazyRetry from "./lazyRetry";
import PrivacyPolicy from "shared/PrivacyPolicy";
import { ElementType } from "react";

/* ======================   
 * Main Layout
 ======================== */
const HealthCheck = lazy(() => lazyRetry(() => import("shared/HealthCheck")));
const MainLayout = lazy(() => lazyRetry(() => import("layouts/MainLayout")));
const NotFound = lazy(() =>
  lazyRetry(() => import("components/ASMManagement/NotFound"))
);
const Login = lazy(() => lazyRetry(() => import("shared/Authentication")));

/* ======================
 * Dashboard Layout
 ======================== */
const DashboardLayout = lazy(() =>
  lazyRetry(() => import("layouts/DashboardLayout"))
);

/* ======================
 * Area Sales Manager Routes
 ======================== */

const ASMDashboard = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/Dashboard"))
);
const BDEManagement = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/BDEManagement"))
);
const AddNewBDE = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/BDEManagement/AddNewBDE"))
);
const BDEProfile = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/BDEManagement/BDEProfile"))
);

const AssignNewRetailers = lazy(() =>
  lazyRetry(() => import("components/BdeProfile/AssignedNewReatilers"))
);

const AreaManagement = lazy(() =>
  lazyRetry(() => import("components/ASMManagement/AreaManagement"))
);

const RetailersManagement = lazy(() =>
  lazyRetry(() => import("components/RetailerManagement"))
);
const RetailerProfile = lazy(() =>
  lazyRetry(() => import("components/RetailerManagement/RetailerProfile/index"))
);

/* ======================
 * Regional Sales Manager Routes
 ======================== */
const RMDashboard = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager/Dashboard"))
);
const ASMManagement = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager"))
);

const AddNewASM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager/AddNewASM"))
);

const ASMProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager/ASMProfile"))
);

const EditASMArea = lazy(() =>
  lazyRetry(() => import("components/AsmProfile/EditArea"))
);

/* ======================
 * Country Head Routes
 ======================== */

const CountryHeadDashboard = lazy(() =>
  lazyRetry(() => import("shared/CountryHead/Dashboard"))
);
const RMList = lazy(() =>
  lazyRetry(() => import("shared/CountryHead/RM/list"))
);
const RMProfile = lazy(() =>
  lazyRetry(() => import("shared/CountryHead/RM/profile"))
);

/* ======================
 * Discussion Card
 ======================== */

const DiscussionCard = lazy(() =>
  lazyRetry(() => import("components/DiscussionCard"))
);
const AddNewDC = lazy(() =>
  lazyRetry(() => import("components/DiscussionCard/AddNewCard"))
);

const Download = lazy(() => lazyRetry(() => import("components/Download")));

/* ======================
 * Feedback
 ======================== */

const Feedback = lazy(() => lazyRetry(() => import("components/Feedback")));

/* ======================
 * Escalation 
 ======================== */

const Escalation = lazy(() => lazyRetry(() => import("components/Escalation")));

/* ======================
 * NDR 
 ======================== */

const NDR = lazy(() => lazyRetry(() => import("components/NDR")));

/* ======================
 * Performance ( Attendance | Visit History ) 
 ======================== */

const PerformanceManagement = lazy(() =>
  lazyRetry(() => import("components/Performance"))
);

/* ======================
 * Motivator (Target and Achievement)
 ======================== */
const Motivator = lazy(() => lazyRetry(() => import("components/motivator")));

/* ======================
 // Pincode Management
 ======================== */
const PincodeManagement = lazy(() =>
  lazyRetry(() => import("components/PincodeManagement"))
);

/* ======================
 // Cluster Sales Management
 ======================== */

const ClusterManagerDashboard = lazy(() =>
  lazyRetry(() => import("shared/ClusterSalesManager/Dashboard"))
);

const CSMManagement = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2"))
);

const AddNewCSM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/AddNewCSM"))
);

const AddNewASMByCSM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/AddNewASM"))
);

const CSMProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/CSMProfile"))
);

const CsmAddNewBDE = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/BDEManagement/AddNewBDEForCSM"))
);

const CsmAddNewBDEForASM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/BDEManagement/AddNewBDEForASM"))
);

const CsmBDEProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/BDEManagement/BDEProfile"))
);

const CsmASMProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/ASMProfile"))
);

const EditCSMArea = lazy(() =>
  lazyRetry(() => import("components/CSMProfile/EditArea"))
);

const EditAsmAreaUnderCSM = lazy(() =>
  lazyRetry(() => import("components/CSMProfile/AsmProfile/EditArea"))
);

// Icons
import { ReactComponent as DashboardIcon } from "assets/img/dashboard.svg";
import { ReactComponent as BDEIcon } from "assets/img/bdeIcon.svg";
import { ReactComponent as AttendanceIcon } from "assets/img/attendance.svg";
import { ReactComponent as SalesIcon } from "assets/img/sales.svg";
import { ReactComponent as EscalationIcon } from "assets/img/escalation.svg";
import { ReactComponent as RetailerIcon } from "assets/img/retailer.svg";
import { ReactComponent as NDRIcon } from "assets/img/ndr.svg";
import { ReactComponent as FeedbackIcon } from "assets/img/feedback.svg";
import { ReactComponent as MotivatorIcon } from "assets/img/motivator.svg";

export interface RoutesType {
  path: string;
  element: JSX.Element;
  permissions?: string[];
  icon?: ElementType;
  title?: string;
  sidebarItem?: boolean;
}

export const privateRoutes: RoutesType[] = [
  // Dashboard
  {
    path: "app/ch-dashboard",
    element: <CountryHeadDashboard />,
    permissions: ["web_nav_ch_dashboard"],
    title: "Dashboard",
    icon: DashboardIcon,
    sidebarItem: true,
  },
  {
    path: "app/rsm-dashboard",
    element: <RMDashboard />,
    permissions: ["web_nav_rsm_dashboard"],
    title: "Dashboard",
    icon: DashboardIcon,
    sidebarItem: true,
  },
  {
    path: "app/asm-dashboard",
    element: <ASMDashboard />,
    permissions: ["web_nav_asm_dashboard"],
    title: "Dashboard",
    icon: DashboardIcon,
    sidebarItem: true,
  },
  {
    path: "app/csm-dashboard",
    element: <ClusterManagerDashboard />,
    permissions: ["web_nav_csm_dashboard"],
    title: "Dashboard",
    icon: DashboardIcon,
    sidebarItem: true,
  },

  // RM Management
  {
    path: "app/regional-manager",
    element: <RMList />,
    permissions: ["web_nav_rsm_management"],
    title: "Regional Manager",
    icon: BDEIcon,
    sidebarItem: true,
  },
  {
    path: "app/regional-manager/:id",
    element: <RMProfile />,
    permissions: ["web_nav_rsm_management"],
  },

  // CSM Management
  {
    path: "app/csm-management",
    element: <CSMManagement />,
    permissions: ["web_nav_csm_management"],
    title: "Cluster Sales Manager",
    icon: BDEIcon,
    sidebarItem: true,
  },
  {
    path: "app/csm-management/add-csm",
    element: <AddNewCSM />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/add-asm",
    element: <AddNewASMByCSM />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/csm-profile/:id",
    element: <CSMProfile />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/csm-profile/add-bde/:manager/:csm_id", //TODO: verify api changes if csm id is enough
    element: <CsmAddNewBDE />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/bde-profile/:manager/:id",
    element: <CsmBDEProfile />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/bde-profile/assign/:manager/:id",
    element: <AssignNewRetailers />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/edit-area/:id",
    element: <EditCSMArea />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/asm-profile/edit-area/:manager/:id",
    element: <EditAsmAreaUnderCSM />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/retailer-profile/:manager/:id",
    element: <RetailerProfile />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/asm-profile/:id",
    element: <CsmASMProfile />,
    permissions: ["web_nav_csm_management"],
  },
  {
    path: "app/csm-management/asm-profile/add-bde/:asm_id",
    element: <CsmAddNewBDEForASM />,
    permissions: ["web_nav_csm_management"],
  },


  // ASM Management
  {
    path: "app/asm-management",
    element: <ASMManagement />,
    permissions: ["web_nav_asm_management"],
    title: "Area Sales Manager",
    icon: BDEIcon,
    sidebarItem: true,
  },
  {
    path: "app/asm-management/add",
    element: <AddNewASM />,
    permissions: ["web_nav_asm_management"],
  },
  {
    path: "app/asm-management/asm-profile/:id",
    element: <ASMProfile />,
    permissions: ["web_nav_asm_management"],
  },
  {
    path: "app/asm-management/asm-profile/add-bde/:asm_id",
    element: <AddNewBDE />,
    permissions: ["web_nav_asm_management"],
  },
  {
    path: "app/asm-management/bde-profile/:id",
    element: <BDEProfile />,
    permissions: ["web_nav_asm_management"],
  },
  {
    path: "app/asm-management/bde-profile/assign/:id",
    element: <AssignNewRetailers />,
    permissions: ["web_nav_asm_management"],
  },
  {
    path: "app/asm-management/bde-profile/edit/:id",
    element: <EditASMArea />,
    permissions: ["web_nav_asm_management"],
  },
  {
    path: "app/asm-management/retailer-profile/:id",
    element: <RetailerProfile />,
    permissions: ["web_nav_asm_management"],
  },

  // BDE Management
  {
    path: "app/bde-management",
    element: <BDEManagement />,
    permissions: ["web_nav_bde_management"],
    title: "BDE Management",
    icon: BDEIcon,
    sidebarItem: true,
  },
  {
    path: "app/bde-management/add",
    element: <AddNewBDE />,
    permissions: ["web_nav_bde_management"],
  },
  {
    path: "app/bde-management/bde-profile/:id",
    element: <BDEProfile />,
    permissions: ["web_nav_bde_management"],
  },
  {
    path: "app/bde-management/assigne/:id",
    element: <AssignNewRetailers />,
    permissions: ["web_nav_bde_management"],
  },

  // Feedback
  {
    path: "app/feedback",
    element: <Feedback />,
    permissions: ["web_nav_orders_feedback"],
    title: "Orders Feedback",
    icon: FeedbackIcon,
    sidebarItem: true,
  },

  // Discipline Management (Attendance)
  {
    path: "app/attendances",
    element: <PerformanceManagement />,
    permissions: ["web_nav_discipline_management"],
    title: "Discipline Management",
    icon: AttendanceIcon,
    sidebarItem: true,
  },

  // Sales Task
  {
    path: "app/sales-task",
    element: <DiscussionCard />,
    permissions: ["web_nav_sales_task"],
    title: "Sales Task",
    icon: SalesIcon,
    sidebarItem: true,
  },
  {
    path: "app/sales-task/add",
    element: <AddNewDC />,
    permissions: ["web_nav_sales_task"],
  },

  // Escalation
  {
    path: "app/escalation",
    element: <Escalation />,
    permissions: ["web_nav_escalation"],
    title: "Escalation",
    icon: EscalationIcon,
    sidebarItem: true,
  },

  // Retailer Management
  {
    path: "app/retailer-management",
    element: <RetailersManagement />,
    permissions: ["web_nav_retailer_management"],
    title: "Retailer Management",
    icon: RetailerIcon,
    sidebarItem: true,
  },
  {
    path: "app/retailer-management/retailer-profile/:id",
    element: <RetailerProfile />,
    permissions: ["web_nav_retailer_management"],
  },

  // NDR
  {
    path: "app/ndr",
    element: <NDR />,
    permissions: ["web_nav_ndr"],
    title: "Non Delivery Report (NDR)",
    icon: NDRIcon,
    sidebarItem: true,
  },

  // Motivator
  {
    path: "app/motivator",
    element: <Motivator />,
    permissions: ["web_nav_motivator"],
    title: "Motivator (Beta)",
    icon: MotivatorIcon,
    sidebarItem: true,
  },

  // { path: "app/pincode-management", element: <PincodeManagement />, permissions: [] },

  // Area Management
  {
    path: "app/area-management",
    element: <AreaManagement />,
    permissions: ["web_nav_asm_management"],
  },

  // Download
  {
    path: "app/download",
    element: <Download />,
    permissions: ["web_nav_download"],
  },
];

export const publicRoutes = [
  { path: "privacypolicy", element: <PrivacyPolicy /> },
  { path: "health-check", element: <HealthCheck /> },
  { path: "error", element: <div>Something went wrong!</div> },
  { path: "*", element: <Navigate to="/404" /> },
];

export const getHomePagePath = (user: any) => {
  const userRole = user?.user_role;
  switch (userRole) {
    case "regional_manager":
      return { path: "/", element: <Navigate to="/app/rsm-dashboard" /> };
    case "mis_manager":
      return { path: "/", element: <Navigate to="/app/rsm-dashboard" /> };
    case "area_sales_manager":
      return { path: "/", element: <Navigate to="/app/asm-dashboard" /> };
    case "cluster_sales_manager":
      return { path: "/", element: <Navigate to="/app/csm-dashboard" /> };
    case "country_head":
      return { path: "/", element: <Navigate to="/app/ch-dashboard" /> };
    default:
      return { path: "/", element: <Navigate to="/error" /> };
  }
};
