export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
export const HAS_PERMISSION = "HAS_PERMISSION";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const LOGOUT_SUCCESSFULL = "LOGOUT_SUCCESSFULL";
export const GET_ACCESS_URLS_OF_CURRENT_USERS =
  "GET_ACCESS_URLS_OF_CURRENT_USERS";
export const SET_SIDEBAR_ITEMS = "SET_SIDEBAR_ITEMS";
export const SET_PERMISSIONS_V2 = "SET_PERMISSIONS_V2";
export const GET_PERMISSIONS_OF_CURRENT_USER =
  "GET_PERMISSIONS_OF_CURRENT_USER";
