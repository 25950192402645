import { lazy } from "react";
import { Navigate } from "react-router-dom";
import lazyRetry from "./lazyRetry";
import PrivacyPolicy from "shared/PrivacyPolicy";

/* ======================   
 * Main Layout
 ======================== */
const HealthCheck = lazy(() => lazyRetry(() => import("shared/HealthCheck")));
const MainLayout = lazy(() => lazyRetry(() => import("layouts/MainLayout")));
const NotFound = lazy(() =>
  lazyRetry(() => import("components/ASMManagement/NotFound"))
);
const Login = lazy(() => lazyRetry(() => import("shared/Authentication")));

/* ======================
 * Dashboard Layout
 ======================== */
const DashboardLayout = lazy(() =>
  lazyRetry(() => import("layouts/DashboardLayout"))
);

/* ======================
 * Area Sales Manager Routes
 ======================== */

const ASMDashboard = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/Dashboard"))
);
const BDEManagement = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/BDEManagement"))
);
const AddNewBDE = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/BDEManagement/AddNewBDE"))
);
const BDEProfile = lazy(() =>
  lazyRetry(() => import("shared/AreaManager/BDEManagement/BDEProfile"))
);

const AssignNewRetailers = lazy(() =>
  lazyRetry(() => import("components/BdeProfile/AssignedNewReatilers"))
);

const AreaManagement = lazy(() =>
  lazyRetry(() => import("components/ASMManagement/AreaManagement"))
);

const RetailersManagement = lazy(() =>
  lazyRetry(() => import("components/RetailerManagement"))
);
const RetailerProfile = lazy(() =>
  lazyRetry(() => import("components/RetailerManagement/RetailerProfile/index"))
);

/* ======================
 * Regional Sales Manager Routes
 ======================== */
const RMDashboard = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager/Dashboard"))
);
const RSMManagement = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager"))
);

const AddNewRSM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager/AddNewASM"))
);

const ASMProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManager/ASMProfile"))
);

const EditASMArea = lazy(() =>
  lazyRetry(() => import("components/AsmProfile/EditArea"))
);

/* ======================
 * Country Head Routes
 ======================== */

const CountryHeadDashboard = lazy(() =>
  lazyRetry(() => import("shared/CountryHead/Dashboard"))
);
const RMList = lazy(() =>
  lazyRetry(() => import("shared/CountryHead/RM/list"))
);
const RMProfile = lazy(() =>
  lazyRetry(() => import("shared/CountryHead/RM/profile"))
);

/* ======================
 * Discussion Card
 ======================== */

const DiscussionCard = lazy(() =>
  lazyRetry(() => import("components/DiscussionCard"))
);
const AddNewDC = lazy(() =>
  lazyRetry(() => import("components/DiscussionCard/AddNewCard"))
);

const Download = lazy(() => lazyRetry(() => import("components/Download")));

/* ======================
 * Feedback
 ======================== */

const Feedback = lazy(() => lazyRetry(() => import("components/Feedback")));

/* ======================
 * Escalation 
 ======================== */

const Escalation = lazy(() => lazyRetry(() => import("components/Escalation")));

/* ======================
 * NDR 
 ======================== */

const NDR = lazy(() => lazyRetry(() => import("components/NDR")));

/* ======================
 * Performance ( Attendance | Visit History ) 
 ======================== */

const PerformanceManagement = lazy(() =>
  lazyRetry(() => import("components/Performance"))
);

/* ======================
 * Motivator (Target and Achievement)
 ======================== */
const Motivator = lazy(() => lazyRetry(() => import("components/motivator")));

/* ======================
 // Pincode Management
 ======================== */
const PincodeManagement = lazy(() =>
  lazyRetry(() => import("components/PincodeManagement"))
);

//Cluster Sales Management

const CSMManagement = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2"))
);

const AddNewCSM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/AddNewCSM"))
);

const AddNewASMByCSM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/AddNewASM"))
);

const CSMProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/CSMProfile"))
);

const CsmAddNewBDE = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/BDEManagement/AddNewBDEForCSM"))
);

const CsmAddNewBDEForASM = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/BDEManagement/AddNewBDEForASM"))
);

const CsmBDEProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/BDEManagement/BDEProfile"))
);

const CsmASMProfile = lazy(() =>
  lazyRetry(() => import("shared/RegionalManagerV2/ASMProfile"))
);

const EditCSMArea = lazy(() =>
  lazyRetry(() => import("components/CSMProfile/EditArea"))
);

const EditAsmAreaUnderCSM = lazy(() =>
  lazyRetry(() => import("components/CSMProfile/AsmProfile/EditArea"))
);

const ClusterManagerDashboard = lazy(() =>
  lazyRetry(() => import("shared/ClusterSalesManager/Dashboard"))
);


// Have to update in better way

const routes = (isAuthenticated: boolean, user: any) => {
  if (
    user?.user_role === "regional_manager" ||
    user?.user_role === "mis_manager"
  ) {
    return [
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/",
        element: isAuthenticated ? (
          <Navigate to="/app/rsm-dashboard" />
        ) : (
          <MainLayout />
        ),
        children: [
          { path: "/", element: <Navigate to="/app/rsm-dashboard" /> },
          { path: "/app", element: <Navigate to="/app/rsm-dashboard" /> },
          { path: "/404", element: <NotFound /> },
          { path: "/login", element: <Login /> },
          { path: "/health-check", element: <HealthCheck /> },
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
      {
        path: "app",
        element: isAuthenticated ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
        children: [
          // Regional Sales Manager Dashboard
          { path: "rsm-dashboard", element: <RMDashboard /> },
          { path: "asm-management", element: <RSMManagement /> },
          { path: "asm-management/add", element: <AddNewRSM /> },
          { path: "asm-management/asm-profile/:id", element: <ASMProfile /> },
          {
            path: "asm-management/asm-profile/add-bde/:asm_id",
            element: <AddNewBDE />,
          },
          { path: "asm-management/bde-profile/:id", element: <BDEProfile /> },
          {
            path: "asm-management/bde-profile/assign/:id",
            element: <AssignNewRetailers />,
          },
          {
            path: "asm-management/bde-profile/edit/:id",
            element: <EditASMArea />,
          },
          {
            path: "asm-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },

          {
            path: "csm-management",
            element: <CSMManagement />,
          },
          {
            path: "csm-management/add-csm",
            element: <AddNewCSM />,
          },
          {
            path: "csm-management/add-asm",
            element: <AddNewASMByCSM />,
          },
          {
            path: "csm-management/csm-profile/:id",
            element: <CSMProfile />,
          },
          {
            path: "csm-management/csm-profile/add-bde/:manager/:csm_id", //TODO: verify api changes if csm id is enough
            element: <CsmAddNewBDE />,
          },
          {
            path: "csm-management/bde-profile/:manager/:id",
            element: <CsmBDEProfile />,
          },
          {
            path: "csm-management/bde-profile/assign/:manager/:id",
            element: <AssignNewRetailers />,
          },
          {
            path: "csm-management/edit-area/:id",
            element: <EditCSMArea />,
          },
          {
            path: "csm-management/asm-profile/edit-area/:manager/:id",
            element: <EditAsmAreaUnderCSM />,
          },
          {
            path: "csm-management/retailer-profile/:manager/:id",
            element: <RetailerProfile />,
          },
          {
            path: "csm-management/asm-profile/:id",
            element: <CsmASMProfile />,
          },
          {
            path: "csm-management/asm-profile/add-bde/:asm_id",
            element: <CsmAddNewBDEForASM />,
          },

          { path: "sales-task", element: <DiscussionCard /> },
          { path: "sales-task/add", element: <AddNewDC /> },

          { path: "download", element: <Download /> },
          { path: "escalation", element: <Escalation /> },
          { path: "ndr", element: <NDR /> },
          { path: "attendances", element: <PerformanceManagement /> },
          { path: "retailer-management", element: <RetailersManagement /> },
          {
            path: "retailer-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },
          {
            path: "motivator",
            element: <Motivator />,
          },
          // Not Found
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
    ];
  } else if (
    user?.user_role === "cluster_sales_manager"
  ) {
    return [
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/",
        element: isAuthenticated ? (
          <Navigate to="/app/csm-dashboard" />
        ) : (
          <MainLayout />
        ),
        children: [
          { path: "/", element: <Navigate to="/app/csm-dashboard" /> },
          { path: "/app", element: <Navigate to="/app/csm-dashboard" /> },
          { path: "/404", element: <NotFound /> },
          { path: "/login", element: <Login /> },
          { path: "/health-check", element: <HealthCheck /> },
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
      {
        path: "app",
        element: isAuthenticated ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
        children: [
          // Regional Sales Manager Dashboard
          { path: "csm-dashboard", element: <ClusterManagerDashboard /> },
          { path: "asm-management", element: <RSMManagement /> },
          { path: "asm-management/add", element: <AddNewRSM /> },
          { path: "asm-management/asm-profile/:id", element: <ASMProfile /> },
          {
            path: "asm-management/asm-profile/add-bde/:asm_id",
            element: <AddNewBDE />,
          },
          { path: "asm-management/bde-profile/:id", element: <BDEProfile /> },
          {
            path: "asm-management/bde-profile/assign/:id",
            element: <AssignNewRetailers />,
          },
          {
            path: "asm-management/bde-profile/edit/:id",
            element: <EditASMArea />,
          },
          {
            path: "asm-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },

          { path: "bde-management", element: <BDEManagement /> },
          { path: "bde-management/add", element: <AddNewBDE /> },
          { path: "bde-management/bde-profile/:id", element: <BDEProfile /> },
          {
            path: "bde-management/assigne/:id",
            element: <AssignNewRetailers />,
          },

          { path: "sales-task", element: <DiscussionCard /> },
          { path: "sales-task/add", element: <AddNewDC /> },

          { path: "download", element: <Download /> },
          { path: "escalation", element: <Escalation /> },
          { path: "ndr", element: <NDR /> },
          { path: "attendances", element: <PerformanceManagement /> },
          { path: "retailer-management", element: <RetailersManagement /> },
          {
            path: "retailer-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },
          {
            path: "motivator",
            element: <Motivator />,
          },
          // Not Found
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
    ];
  }
  else if (user?.user_role === "area_sales_manager") {
    return [
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/",
        element: isAuthenticated ? (
          <Navigate to="/app/asm-dashboard" />
        ) : (
          <MainLayout />
        ),
        children: [
          { path: "/", element: <Navigate to="/app/asm-dashboard" /> },
          { path: "/app", element: <Navigate to="/app/asm-dashboard" /> },
          { path: "404", element: <NotFound /> },
          { path: "/login", element: <Login /> },
          { path: "/health-check", element: <HealthCheck /> },
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
      {
        path: "app",
        element: isAuthenticated ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
        children: [
          { path: "asm-dashboard", element: <ASMDashboard /> },
          { path: "retailer-management", element: <RetailersManagement /> },
          {
            path: "retailer-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },
          { path: "bde-management", element: <BDEManagement /> },
          { path: "bde-management/add", element: <AddNewBDE /> },
          { path: "bde-management/bde-profile/:id", element: <BDEProfile /> },
          {
            path: "bde-management/assigne/:id",
            element: <AssignNewRetailers />,
          },
          { path: "area-management", element: <AreaManagement /> },
          { path: "sales-task", element: <DiscussionCard /> },
          // { path: "feedback", element: <Feedback /> }, Hide for a moment
          { path: "sales-task/add", element: <AddNewDC /> },
          { path: "download", element: <Download /> },
          { path: "escalation", element: <Escalation /> },
          { path: "ndr", element: <NDR /> },
          { path: "attendances", element: <PerformanceManagement /> },
          {
            path: "motivator",
            element: <Motivator />,
          },
          // Not Found
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
    ];
  }
  if (user?.user_role === "country_head") {
    return [
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/",
        element: isAuthenticated ? (
          <Navigate to="/app/ch-dashboard" />
        ) : (
          <MainLayout />
        ),
        children: [
          { path: "/", element: <Navigate to="/app/ch-dashboard" /> },
          { path: "/app", element: <Navigate to="/app/ch-dashboard" /> },
          { path: "404", element: <NotFound /> },
          { path: "/login", element: <Login /> },
          { path: "/health-check", element: <HealthCheck /> },
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
      {
        path: "app",
        element: isAuthenticated ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
        children: [
          { path: "ch-dashboard", element: <CountryHeadDashboard /> },
          { path: "regional-manager", element: <RMList /> },
          {
            path: "regional-manager/:id",
            element: <RMProfile />,
          },
          { path: "retailer-management", element: <RetailersManagement /> },
          {
            path: "retailer-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },
          { path: "download", element: <Download /> },
          { path: "feedback", element: <Feedback /> }, // Feedback
          { path: "sales-task", element: <DiscussionCard /> },
          { path: "sales-task/add", element: <AddNewDC /> },
          { path: "escalation", element: <Escalation /> },
          { path: "ndr", element: <NDR /> },
          { path: "attendances", element: <PerformanceManagement /> },
          // Pincode Management     commented for now
          // {
          //   path: "pincode-management",
          //   element: <PincodeManagement />,
          // },
          // Not Found
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
    ];
  } else {
    // Rendering routes in kind of sandbox
    // it will take back if some routes is distracted
    return [
      { path: "/privacypolicy", element: <PrivacyPolicy /> },
      {
        path: "/",
        element: isAuthenticated ? (
          <Navigate
            to={
              user?.user_role === "country_head"
                ? "/app/ch-management"
                : user?.user_role === "regional_manager" ||
                  user?.user_role === "mis_manager"
                  ? "/app/rsm-dashboard"
                  : "/app/asm-dashboard"
            }
          />
        ) : (
          <MainLayout />
        ),
        children: [
          {
            path: "/",
            element: (
              <Navigate
                to={
                  user?.user_role === "country_head"
                    ? "/app/ch-management"
                    : user?.user_role === "regional_manager" ||
                      user?.user_role === "mis_manager"
                      ? "/app/rsm-dashboard"
                      : "/app/asm-dashboard"
                }
              />
            ),
          },
          { path: "404", element: <NotFound /> },
          { path: "/login", element: <Login /> },
          { path: "/health-check", element: <HealthCheck /> },
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
      {
        path: "app",
        element: isAuthenticated ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
        children: [
          { path: "asm-dashboard", element: <ASMDashboard /> },
          { path: "retailer-management", element: <RetailersManagement /> },
          {
            path: "retailer-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },
          { path: "bde-management", element: <BDEManagement /> },
          { path: "bde-management/add", element: <AddNewBDE /> },
          { path: "bde-management/bde-profile/:id", element: <BDEProfile /> },
          {
            path: "bde-management/assigne/:id",
            element: <AssignNewRetailers />,
          },
          { path: "area-management", element: <AreaManagement /> },
          { path: "sales-task", element: <DiscussionCard /> },
          { path: "feedback", element: <Feedback /> },
          { path: "sales-task/add", element: <AddNewDC /> },
          { path: "escalation", element: <Escalation /> },
          { path: "ndr", element: <NDR /> },
          { path: "attendances", element: <PerformanceManagement /> },

          // Regional Sales Manager Dashboard
          { path: "rsm-dashboard", element: <RMDashboard /> },
          { path: "asm-management", element: <RSMManagement /> },
          { path: "asm-management/add", element: <AddNewRSM /> },
          { path: "asm-management/asm-profile/:id", element: <ASMProfile /> },
          { path: "asm-management/bde-profile/:id", element: <BDEProfile /> },
          {
            path: "asm-management/bde-profile/edit/:id",
            element: <EditASMArea />,
          },
          {
            path: "asm-management/retailer-profile/:id",
            element: <RetailerProfile />,
          },
          {
            path: "asm-management/bde-profile/assign/:id",
            element: <AssignNewRetailers />,
          },
          {
            path: "asm-management/asm-profile/add-bde/:asm_id",
            element: <AddNewBDE />,
          },

          // Country Head
          { path: "ch-dashboard", element: <CountryHeadDashboard /> },
          { path: "regional-manager", element: <RMList /> },
          {
            path: "regional-manager/:id",
            element: <RMProfile />,
          },

          // Download
          { path: "download", element: <Download /> },

          // Motivator ( Target & Achievement )
          {
            path: "motivator",
            element: <Motivator />,
          },

          // Pincode Management   commented for now
          // {
          //   path: "pincode-management",
          //   element: <PincodeManagement />,
          // },

          // Not Found
          { path: "*", element: <Navigate to="/404" /> },
        ],
      },
    ];
  }
};

export default routes;
