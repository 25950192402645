import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducers from './reducers';

// declare global {
//     interface Window {
//         __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//     }
// }



//  Have to define enhancers only for development 

// const enhancers = [];

// if(process.env.NODE_ENV === 'development'){ 
//     const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//     if(typeof devToolsExtension === 'function'){
//         enhancers.push(devToolsExtension)
//     }
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducers, composeEnhancers(applyMiddleware(thunk)))
export type RootState = ReturnType<typeof rootReducers>

export default store;