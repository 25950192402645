import { createTheme } from "@mui/material";

interface Background {
  default: string;
  paper: string;
  secondary: string;
}

const theme = createTheme({
  palette: {
    background: {
      default: "#f4f6f8",
      paper: "#ffffff",
      secondary: "#fcfcfc",
    } as Background,
    primary: {
      main: "#655AD6",
      contrastText: "#ffffff",
    },
    text: {
      primary: "rgba(0, 19, 37, 0.92)",
      secondary: "rgba(0, 0, 0, 0.72)",
    },
    secondary: {
      main: "rgba(0, 19, 37, 0.64)",
    },
  },
  typography: {
    fontFamily: [
      "'Inter', sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
    ].join(","),

    h1: {
      fontWeight: 600,
      fontSize: "2.25rem",
      letterSpacing: "-0.24px",
      lineHeight: "2.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
      letterSpacing: "-0.24px",
      lineHeight: "2.25rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
      letterSpacing: "-0.06px",
      lineHeight: "1.75rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.25rem",
      letterSpacing: "-0.06px",
      lineHeight: "1.5rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1rem",
      letterSpacing: "-0.05px",
      lineHeight: "24px",
      color: "#001325",
    },
    h6: {
      fontWeight: 500,
      fontSize: "0.875rem",
      letterSpacing: "-0.05px",
      lineHeight: "1.5rem",
      color: "#001325",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "1.5rem",
    },
    overline: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "capitalize",
        },
        outlined: {
          border: "1px solid rgba(0, 19, 37, 0.16)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFilledInput-input": {
            paddingTop: "10px",
            background: "#F3F4F5",
          },
        },
      },
    },
    // MuiInputBase: {
    //     styleOverrides:{
    //         root: {
    //             borderTopLeftRadius: '8px !important',
    //             borderTopRightRadius: '8px !important',
    //             // borderRadius: '8px !important',
    //             // '&:before': {
    //             //     borderRadius: '8px'
    //             // },
    //             // '& .Mui-focused': {
    //             //     borderRadius: '8px'
    //             // }
    //         }
    //     }
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          background: "rgba(101, 90, 214, 0.08)",
          border: "1px solid #655AD6",
          color: "#655AD6",
          "& .MuiChip-deleteIcon": {
            color: "#655AD6",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "rgba(0, 19, 37, 0.64)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#f44336",
            fontWeight: 500,
          },
        },
      },
    },
  },
});

export default theme;
