import axios, { AxiosError, AxiosResponse } from "axios";
import store from "store";
import auth from "store/actions/auth";
import { message } from "components/generic/FosAlert";
import cookies from "utils/cookies";
import { axiosV2 } from "./axios";
import { LOGOUT_SUCCESSFULL } from "store/constants/auth";

const MAX_RETRY_ATTEMPTS = 1; // Maximum number of retry attempts

function handle4xxErrors(status: number): void {
  if (status === 404) {
    message.error("404: Page not found.");
  } else if (status >= 405 && status <= 499) {
    message.error(
      `${status} - There is a problem with your request. Please check your input and try again.`
    );
  } else if (status >= 500) {
    message.error(
      `${status} - Internal Server Error. There is a problem with the server.`
    );
  }
}

export default function axiosInterceptorHandlerV2() {
  const retry = async (requestConfig: any): Promise<AxiosResponse> => {
    message.info("Request canceled. Retrying for a seamless experience.");

    // Create a new cancel token for the retry request
    const cancelToken = axios.CancelToken.source();

    //  Add cancel token to the request config
    requestConfig.cancelToken = cancelToken.token;

    // Delay before retrying (you can customize this delay)
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Retry the request with the new cancel token
    return axiosV2.request(requestConfig);
  };

  axiosV2.interceptors.response.use(
    (response: AxiosResponse) => {
      const { status } = response;

      if (status === 200) {
        return response;
      } else if (status === 201) {
        message.success("201: Resource created successfully.");
        return response;
      } else if (status === 204) {
        message.success("204: No Content.");
        return response;
      } else if (status === 400) {
        message.error(
          "400: Bad Request. Please check your request parameters."
        );
        return Promise.reject(response);
      } else if (status >= 405 && status <= 499) {
        handle4xxErrors(status);
        return Promise.reject(response);
      } else if (status >= 500) {
        message.error(
          "Network Error",
          "There is a problem with your request. Please check your network connection and try again."
        );
        return Promise.reject(response);
      } else {
        message.error(
          "Error: Timeout",
          "We encountered a temporary network issue. Please try again in a moment."
        );
        return Promise.reject(response);
      }
    },
    (error: any) => {
      const originalRequest = error?.config;

      if (!error.response) {
        if (
          error?.code === "ECONNABORTED" &&
          error?.message?.includes("timeout")
        ) {
          message.error(
            "We encountered a temporary network issue. Please try again in a moment."
          );
          originalRequest._retry = originalRequest._retry || 0;

          if (originalRequest._retry < MAX_RETRY_ATTEMPTS) {
            originalRequest._retry++;
            return retry(originalRequest);
          }
        }
      } else if (error.response.status === 401) {
        message.error("401: Unauthorized. Please log in again.");
        cookies.delete("SECURE-TOKEN");
        store.dispatch<any>({ type: LOGOUT_SUCCESSFULL });
      } else if (error.response.status === 403) {
        message.error(
          "403: Forbidden. You don't have permission for this action."
        );
        store.dispatch<any>(auth.checkPermission(false));
      } else if (error.response.status === 500) {
        message.error(
          "500 - Internal Server Error",
          "There is a problem with the resource you are looking for, and it cannot be displayed."
        );
        originalRequest._retry = originalRequest._retry || 0;

        if (originalRequest._retry < MAX_RETRY_ATTEMPTS) {
          originalRequest._retry++;
          return retry(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );
}
