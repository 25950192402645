import { useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RoutesType, getHomePagePath, privateRoutes, publicRoutes } from "routes/routesV2";
import { lazy } from "react";
import lazyRetry from 'routes/lazyRetry';
import { axiosV2 } from 'api/axios';
import SuspenseLoader from 'components/generic/Loader';
import { useDispatch } from 'react-redux';
import { SET_PERMISSIONS_V2, SET_SIDEBAR_ITEMS } from 'store/constants/auth';
import PrivacyPolicy from 'shared/PrivacyPolicy';
import HealthCheck from 'shared/HealthCheck';


//components
const Login = lazy(() => lazyRetry(() => import("shared/Authentication")));
const MainLayout = lazy(() => lazyRetry(() => import("layouts/MainLayout")));
const NotFound = lazy(() =>
  lazyRetry(() => import("components/ASMManagement/NotFound"))
);
const DashboardLayout = lazy(() =>
  lazyRetry(() => import("layouts/DashboardLayout"))
);

interface AppRouterProps {
  user: any
  setRouteConfigV2: any;
  isAuthenticated: boolean;
}


const AppRouter: React.FC<AppRouterProps> = ({ user, isAuthenticated, setRouteConfigV2 }) => {

  const dispatch = useDispatch();

  if (!user) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/health-check" element={<HealthCheck />} />
        <Route path="/error" element={<div>Something went wrong!</div>} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    )
  }

  const [isLoading, setIsLoading] = useState<boolean>();
  const [userPermissions, setUserPermissions] = useState<Array<string>>();

  useEffect(() => {
    const fetchPermissions = async () => {
      await axiosV2.get("permissions/fetch").then((data: any) => {
        const response = data?.data?.permissions ? data?.data?.permissions : [];
        setIsLoading(false);
        setUserPermissions(response);
      }).catch((error) => {
        setRouteConfigV2(false);
        setIsLoading(false);
        console.error(error);
        setUserPermissions([]);
      });
    }
    if (user) {
      setIsLoading(true);
      fetchPermissions();
    }
    else {
      setIsLoading(false);
      setUserPermissions([]);
    }
  }, [user]);

  const authorizedRoutes = useMemo(() => {
    return privateRoutes.filter(route =>
      route?.permissions?.every((permission: any) => userPermissions?.includes(permission))
    )
  }, [userPermissions]);

  const homepageURL = useMemo(() => getHomePagePath(user), [user]);

  const routes: RoutesType[] = [
    ...authorizedRoutes,
    homepageURL,
  ];

  const sidebarItems = useMemo(() => {
    return routes
      .filter(route => route?.sidebarItem === true)
      .map(route => ({
        href: route?.path,
        icon: route?.icon,
        title: route?.title
      }));
  }, [routes]);

  useEffect(() => {
    dispatch({ type: SET_SIDEBAR_ITEMS, data: sidebarItems });
    dispatch({type: SET_PERMISSIONS_V2, data: userPermissions })
  }, [routes])


  if (isLoading === undefined || isLoading || userPermissions === undefined) return <SuspenseLoader />;

  return (
    <Routes>
      <Route path='/' element={<DashboardLayout />}>
        {routes.map((route: any, index) => (
          <Route key={route?.path} path={route?.path} element={user && isAuthenticated ? route?.element : <Navigate to="/login" />} />
        ))}
      </Route>
      <Route path='/' element={<MainLayout />}>
        {publicRoutes.map((route: any) => (
          <Route key={route?.path} path={route?.path} element={route?.element} />
        ))}
      </Route>
      <Route path="/login" element={user && isAuthenticated ? <Navigate to="/" /> : <Login />} />
      <Route path="/404" element={user && isAuthenticated ? <Navigate to="/" /> : <NotFound />} />
    </Routes>
  );

}



export default AppRouter;


