// Constant for redux store (Target and Achievement Module)

// Targets
/* =============================
  Get All Targets for Dashboard Tab
 =============================== */
export const GET_ALL_TARGETS = "GET_ALL_TARGETS";

// export type AllTargetsData = {

// } TODO

export interface IGetAllTargetsActionType {
  type: typeof GET_ALL_TARGETS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Upload Targets Sheet
 =============================== */
export const UPLOAD_TARGETS_SHEET = "UPLOAD_TARGETS_SHEET";

export interface IUploadTargetsSheetActionType {
  type: typeof UPLOAD_TARGETS_SHEET;
  data?: any;
  loading?: boolean;
}

/* =============================
  Get All Targets List
 =============================== */

export const GET_TARGET_LIST = "GET_TARGET_LIST";

export interface ITargetListActionType {
  type: typeof GET_TARGET_LIST;
  data?: any;
  loading?: boolean;
}

/* =============================
  Get All Targets Blocks for Create Campaign Information Form
 =============================== */
export const GET_TARGET_BLOCKS_FOR_CAMPAIGN_INFO_FORM =
  "GET_TARGET_BLOCKS_FOR_CAMPAIGN_INFO_FORM";

export interface IGetTargetBlocksForCampaignInfoFormActionType {
  type: typeof GET_TARGET_BLOCKS_FOR_CAMPAIGN_INFO_FORM;
  data?: any;
  loading?: boolean;
}

// Campaigns
/* =============================
  Get All Campaign for Dashboard Tab
 =============================== */

export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export interface IGetAllCampaignsActionType {
  type: typeof GET_ALL_CAMPAIGNS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Create campaign information 
 =============================== */

export const CREATE_CAMPAIGN_INFORMATION = "CREATE_CAMPAIGN_INFORMATION";

export interface ICreateCampaignInformationActionType {
  type: typeof CREATE_CAMPAIGN_INFORMATION;
  data?: any;
  loading?: boolean;
}

/* =============================
  Get All Campaigns List
 =============================== */

export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";

export interface ICampaignListActionType {
  type: typeof GET_CAMPAIGN_LIST;
  data?: any;
  loading?: boolean;
}

/* =============================
  Get Edit Campaign Details 
 =============================== */

export const GET_EDIT_CAMPAIGN_DETAILS = "GET_EDIT_CAMPAIGN_DETAILS";

export interface IGetEditCampaignDetailsActionType {
  type: typeof GET_EDIT_CAMPAIGN_DETAILS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Get Edit Campaign Condition  
 =============================== */

export const GET_EDIT_CAMPAIGN_CONDITIONS = "GET_EDIT_CAMPAIGN_CONDITIONS";
export interface IGetEditCampaignConditionsActionType {
  type: typeof GET_EDIT_CAMPAIGN_CONDITIONS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Get Edit Campaign Eligibility Condition  
 =============================== */

export const GET_EDIT_CAMPAIGN_ELIGIBILITY_CONDITIONS =
  "GET_EDIT_CAMPAIGN_ELIGIBILITY_CONDITIONS";
export interface IGetEditCampaignEligibilityConditionsActionType {
  type: typeof GET_EDIT_CAMPAIGN_ELIGIBILITY_CONDITIONS;
  data?: any;
  loading?: boolean;
}

/* =============================
  BDE linked -> Campaign Details
 =============================== */
export const GET_CAMPAIGN_DETAILS_BDE_LINKED =
  "GET_CAMPAIGN_DETAILS_BDE_LINKED";

export interface IGetCampaignDetailsBDELinked {
  type: typeof GET_CAMPAIGN_DETAILS_BDE_LINKED;
  data?: any;
  loading?: boolean;
}

/* =============================
  BDE Stats -> Campaign Details
 =============================== */
export const GET_CAMPAIGN_DETAILS_BDE_LINKED_STATS =
  "GET_CAMPAIGN_DETAILS_BDE_LINKED_STATS";

export interface IGetCampaignDetailsBDELinkedStats {
  type: typeof GET_CAMPAIGN_DETAILS_BDE_LINKED_STATS;
  data?: any;
  loading?: boolean;
}

/* =============================
  BDE Incentives -> Campaign Details
 =============================== */
export const GET_CAMPAIGN_DETAILS_BDE_LINKED_INCENTIVES =
  "GET_CAMPAIGN_DETAILS_BDE_LINKED_INCENTIVES";

export interface IGetCampaignDetailsBDELinkedIncentives {
  type: typeof GET_CAMPAIGN_DETAILS_BDE_LINKED_INCENTIVES;
  data?: any;
  loading?: boolean;
}

/* =============================
  ASM linked -> Campaign Details
 =============================== */
export const GET_CAMPAIGN_DETAILS_ASM_LINKED =
  "GET_CAMPAIGN_DETAILS_ASM_LINKED";

export interface IGetCampaignDetailsASMLinked {
  type: typeof GET_CAMPAIGN_DETAILS_ASM_LINKED;
  data?: any;
  loading?: boolean;
}

export type MotivatorActionType =
  | IGetAllTargetsActionType
  | IUploadTargetsSheetActionType
  | ITargetListActionType
  | IGetTargetBlocksForCampaignInfoFormActionType
  | IGetAllCampaignsActionType
  | ICampaignListActionType
  | ICreateCampaignInformationActionType
  | IGetEditCampaignDetailsActionType
  | IGetEditCampaignConditionsActionType
  | IGetEditCampaignEligibilityConditionsActionType
  | IGetCampaignDetailsBDELinked
  | IGetCampaignDetailsBDELinkedStats
  | IGetCampaignDetailsBDELinkedIncentives
  | IGetCampaignDetailsASMLinked;
