// Constants and Action Types
import {
  FeedbackActionType,
  GET_FEEDBACK_STATS,
  GET_FEEDBACK_LIST,
  GET_FILTER_TAGS_LIST,
} from "store/constants/Feedback";

// Initial States

// TODO - Have to define interface for feedback initial states
const initialState = {
  stats: {
    isLoading: false,
    data: null,
  },
  list: {
    isLoading: false,
    data: [],
  },
  tags: {
    isLoading: false,
    data: [],
  },
};

export default function feedbackReducer(
  state: any = initialState,
  action: FeedbackActionType
) {
  // Assigning data to respective states using switching action_types

  switch (action.type) {
    // Feedback Stats
    case GET_FEEDBACK_STATS:
      return {
        ...state,
        stats: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Feedback List
    case GET_FEEDBACK_LIST:
      return {
        ...state,
        list: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Filter Tags List
    case GET_FILTER_TAGS_LIST:
      return {
        ...state,
        tags: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Returning all states by defaults
    default:
      return { ...state };
  }
}
