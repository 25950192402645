import {
  DCActionTypes,
  GET_DC_LIST,
  GET_CONDITION_OPTIONS,
  GET_SUB_CONDITION_OPTIONS,
  GET_STORE_TYPE_OPTIONS,
  GET_RETAILERS_TAGGED_LIST,
  SHOW_TAGGED_RETAILER_COUNT,
  GET_DC_REPORT_LIST,
  GET_FEEDBACK_RECEIVED,
  UPLOAD_RETAILER_LIST,
  GET_FEEDBACK_OPTIONS,
  DC_LIST_LOADING,
  GET_SEQUENCE_OPTIONS,
} from "store/constants/DiscussionCard";

import { LOCATION_CHANGE } from "store/constants/changeLocation";

const initialState = {
  dcList: {
    isLoading: false,
    data: [],
  },
  dcListLoading: {
    loading: false,
  },
  RetailersTaggedList: {
    isLoading: false,
    data: [],
  },

  dcReportList: {
    isLoading: false,
    data: [],
  },

  feedbackReceived: {
    isLoading: false,
    data: [],
  },

  conditionOptions: {
    isLoading: false,
    data: [],
  },
  subConditionOptions: {
    results: [],
  },

  uploadList: {
    data: [],
    isLoading: false,
  },

  storeOptions: {
    data: [],
    isLoading: false,
  },
  taggedRetailer: null,
  feedbackOptions: null,
  sequenceOptions: null,
};

export default function dcReducer(
  state: any = initialState,
  action: DCActionTypes
) {
  switch (action.type) {
    case GET_DC_LIST:
      return {
        ...state,
        dcList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case DC_LIST_LOADING:
      return {
        ...state,
        dcListLoading: {
          isLoading: action.loading,
        },
      };

    case GET_RETAILERS_TAGGED_LIST:
      return {
        ...state,
        RetailersTaggedList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_DC_REPORT_LIST:
      return {
        ...state,
        dcReportList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_FEEDBACK_RECEIVED:
      return {
        ...state,
        feedbackReceived: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case UPLOAD_RETAILER_LIST:
      return {
        ...state,
        uploadList: {
          data: action.data,
          isLoading: action.isLoading,
        },
      };

    case GET_CONDITION_OPTIONS:
      return {
        ...state,
        conditionOptions: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    case GET_SUB_CONDITION_OPTIONS:
      return {
        ...state,
        subConditionOptions: {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.subConditionOptions?.results, ...action.data?.results]
            : [...action.data?.results],
        },
      };

    case GET_STORE_TYPE_OPTIONS:
      let storeOptions = action.data ? [...action.data] : [];
      return {
        ...state,
        storeOptions: {
          data: storeOptions,
          isLoading: action.loading,
        },
      };

    case SHOW_TAGGED_RETAILER_COUNT:
      return { ...state, taggedRetailer: action.data?.store_type };

    case GET_FEEDBACK_OPTIONS:
      return { ...state, feedbackOptions: action.data };

    case GET_SEQUENCE_OPTIONS:
      return { ...state, sequenceOptions: action.data };

    case LOCATION_CHANGE:
      return initialState;

    default:
      return { ...state };
  }
}
