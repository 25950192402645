import {
  IPerformanceStats,
  IChartByOrderRevenue,
  IChartByCategory,
  IRMList,
  IRMLinkedASMList,
  IRMProfile,
  IRMLinkedCSMList,
} from "types/countryHead";

// Dashboard
export const GET_STATS = "GET_STATS";
export const GET_STATS_V2 = "GET_STATS_V2";
export const GET_TOP_RM_LIST = "GET_TOP_RM_LIST";
export const GET_CHART_DATA_BY_CATEGORY = "GET_CHART_DATA_BY_CATEGORY";
export const GET_CHART_DATA_BY_ORDER = "GET_CHART_DATA_BY_ORDER";

// Regional Manager Management
export const GET_RM_LIST = "GET_RM_LIST";
export const GET_RM_PROFILE = "GET_RM_PROFILE";
export const GET_RM_LINKED_ASM = "GET_RM_LINKED_ASM";
export const GET_RM_LINKED_CSM = "GET_RM_LINKED_CSM";
export const GET_RM_STATS = "GET_RM_STATS";

// Interfaces OR Types

// Dashboard

export interface IGetStatsActionType {
  type: typeof GET_STATS;
  data?: IPerformanceStats;
  loading?: boolean;
}

export interface IGetStatsV2ActionType {
  type: typeof GET_STATS_V2;
  data?: any;
  loading?: boolean;
}

export interface ITopRMListActionType {
  type: typeof GET_TOP_RM_LIST;
  data?: any;
  loading?: boolean;
}

export interface IGetChartDataByCategoryActionType {
  type: typeof GET_CHART_DATA_BY_CATEGORY;
  data?: IChartByCategory[];
  loading?: boolean;
}

export interface IGetChartDataByOrderActionType {
  type: typeof GET_CHART_DATA_BY_ORDER;
  data?: IChartByOrderRevenue[];
  loading?: boolean;
}

// Regional Manager
export interface IGetRMActionType {
  type: typeof GET_RM_LIST;
  data?: IRMList;
  loading?: boolean;
}

export interface IGetRMProfileActionType {
  type: typeof GET_RM_PROFILE;
  data: IRMProfile;
}

export interface IGetLinkedASMActionType {
  type: typeof GET_RM_LINKED_ASM;
  data?: IRMLinkedASMList;
  loading?: boolean;
}

export interface IGetLinkedCSMActionType {
  type: typeof GET_RM_LINKED_CSM;
  data?: IRMLinkedCSMList;
  loading?: boolean;
}

export interface IGetRMStatsActionType {
  type: typeof GET_RM_STATS;
  data?: IPerformanceStats;
  loading?: boolean;
}

export type DashboardActionType =
  | ITopRMListActionType
  | IGetChartDataByCategoryActionType
  | IGetChartDataByOrderActionType
  | IGetStatsActionType
  | IGetStatsV2ActionType;

export type RMManagementActionType =
  | IGetRMActionType
  | IGetRMProfileActionType
  | IGetLinkedASMActionType
  | IGetLinkedCSMActionType
  | IGetRMStatsActionType;

export type CHActionType = DashboardActionType | RMManagementActionType;
