import { Theme, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const LoaderStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  dropdown: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  logo: {
    marginBottom: theme.spacing(2),
  },
  loader: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10%",
  },
  backdropLoader: {
    color: "#655AD6",
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.75)",
    zIndex: 999,
  },
}));

export const FileUploader = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
    height: 250,
    backgroundColor: "rgba(101, 90, 214, 0.08)",
    border: "1px dashed #655AD6",
    borderRadius: 8,
    opacity: 1,
    position: "relative",
  },
  rootSuccess: {
    width: "100%",
    overflow: "hidden",
    height: 250,
    backgroundColor: "rgba(25, 127, 77, 0.08)",
    border: "1px dashed #197F4D",
    borderRadius: 8,
    opacity: 1,
    position: "relative",
    padding: "16px",
  },
  rootError: {
    width: "100%",
    overflow: "hidden",
    height: 250,
    backgroundColor: "rgba(182, 0, 0, 0.04)",
    border: "1px dashed #B60000",
    borderRadius: 8,
    opacity: 1,
    position: "relative",
    padding: "16px",
  },
  input: {
    padding: 0,
    margin: 0,
    cursor: "pointer",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0,
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  isImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60%",
  },
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 130,
  },
  loading: {
    alignSelf: "center",
    justifyContent: "center",
    alignContent: "center",
    position: "absolute",
  },
  icon: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "2px",
    color: "#655AD6",
    cursor: "pointer",
    zIndex: 999,
    position: "inherit",
  },
  text: {
    color: "#655AD6",
    width: 350,
    textAlign: "center",
    padding: 4,
  },
  imgName: {
    color: "rgba(0, 19, 37, 0.64)",
    fontSize: 12,
    textAlign: "center",
    padding: 4,
  },
  text1: {
    color: "rgba(0, 19, 37, 0.64)",
    width: 350,
    textAlign: "center",
    padding: 4,
  },

  imgcontainer: {
    width: 194,
    height: 148,
    marginLeft: 40,
    border: "1px solid rgba(0, 19, 37, 0.16)",
    borderRadius: 8,
  },
  img: {
    width: 192,
    height: 108,
    borderRadius: 6,
  },
  imgrow: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
  },
}));

export const FosInputStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      padding: theme.spacing(0.6, 0),
    },
  },
}));

export const FosFilterDate = makeStyles((theme: Theme) => ({
  textField: {
    border: "1px solid rgba(0, 19, 37, 0.16)",
    backgroundColor: "rgba(101, 90, 214, 0.08)",
    borderRadius: 8,
    height: 40,
    // minWidth: 150,
    padding: "8px 12px",
    // marginRight: "30px",
  },
}));

export const lightBoxStyles = makeStyles((theme: Theme) => ({
  lightBox: {
    height: 500,
    margin: "auto",
    width: "90%",
  },
  clear: {
    width: 48,
    height: 48,
    background: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "10%",
    marginBottom: 16,
    border: "1px solid rgba(0, 19, 37, 0.16)",
    alignSelf: "center",
    borderRadius: 50,
    padding: "12px 0px",
    textAlign: "center",
    cursor: "pointer",
  },
  container: {
    height: 420,
    //   border: "1px solid rgba(0, 19, 37, 0.16)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  arrow: {
    width: 48,
    height: 48,
    background: "#FFFFFF",
    marginTop: 185,
    border: "1px solid rgba(0, 19, 37, 0.16)",
    borderRadius: 50,
    padding: "12px 0px",
    textAlign: "center",
    cursor: "pointer",
  },
  imageCard: {
    width: 100,
    height: 100,
    margin: 6,
    cursor: "pointer",
  },

  lightboxImg: {
    height: "auto",
    width: "auto",
    maxWidth: "80%",
    background: "#fff",
  },

  lightboxContainer: {
    zIndex: 12,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
  },
}));

export const drawerStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(4),
    // [theme.breakpoints.down("lg")]: {
    //   marginTop: "0px !important",
    //   width: "70%",
    // },
    // [theme.breakpoints.down("md")]: {
    //   width: "80%",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  rootWithCleanComponent: {
    width: "100%",
    // [theme.breakpoints.down("lg")]: {
    //   marginTop: "0px !important",
    //   width: "70%",
    // },
    // [theme.breakpoints.down("md")]: {
    //   width: "80%",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },

  rootPaper: {
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      marginTop: "0px !important",
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rootWithFullWith: {
    width: "100%",
  },
}));

export const TableRoot = styled("div")(({ theme }: { theme: Theme }) => ({
  "& table": {
    fontSize: "0.875rem",
    borderCollapse: "collapse",
    width: "100%",
  },
  "& td, th": {
    border: "1px solid #EEEEEE",
    // textAlign: 'left',
    padding: theme.spacing(1.5),
  },
  "& tr:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
  },
  "& th": {
    backgroundColor: "#F3F4F5",
    // fontSize: "1rem",
    fontWeight: 500,
  },
  "& svg": {},
  "& .MuiIconButton-root": {
    padding: 0,
  },
}));
