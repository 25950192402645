export const GET_ESCALATION_LIST = "GET_ESCALATION_LIST";
export const GET_ESCALATION_STATS = "GET_ESCALATION_STATS";
export const GET_ESCALATION_DETAILS = "GET_ESCALATION_DETAILS";
export const GET_ESCALATION_REASON_OPTIONS = "GET_ESCALATION_REASON_OPTIONS";
export const GET_ESCALATION_CONVERSATION = "GET_ESCALATION_CONVERSATION";
export const GET_UNMASKED_NUMBER = "GET_UNMASKED_NUMBER";
export interface IGetListActionTypes {
  type: typeof GET_ESCALATION_LIST;
  data?: any;
  loading?: boolean;
}
export interface IGetStatsActionTypes {
  type: typeof GET_ESCALATION_STATS;
  data?: any;
  loading?: boolean;
}

export interface IGetDetailsActionTypes {
  type: typeof GET_ESCALATION_DETAILS;
  data?: any;
  loading?: boolean;
}

export interface IGetReasonOptionsActionTypes {
  type: typeof GET_ESCALATION_REASON_OPTIONS;
  data?: any;
}
export interface IGetConversationActionTypes {
  type: typeof GET_ESCALATION_CONVERSATION;
  data?: any;
  loading?: boolean;
}

export interface IGetUnmaskedActionTypes {
  type: typeof GET_UNMASKED_NUMBER;
  data?: any;
}

export type EscalationActionTypes =
  | IGetListActionTypes
  | IGetStatsActionTypes
  | IGetDetailsActionTypes
  | IGetReasonOptionsActionTypes
  | IGetConversationActionTypes
  | IGetUnmaskedActionTypes;
