// constants and action types
import {
  GET_DETAILS,
  GET_DETAILS_LIST,
  GET_LIST,
  GET_STATS,
  GET_STORE_ONBOARD_RETAILER_LIST,
  GET_LEAD_SAVED_RETAILER_LIST,
  VisitHistoryActionTypes,
} from "store/constants/visitHistory";

// Initial States

const initialState = {
  stats: {
    isLoading: false,
    data: null,
  },
  list: {
    isLoading: false,
    data: null,
  },
  details: {
    isLoading: false,
    data: null,
  },
  detailList: {
    isLoading: false,
    data: null,
  },
  storeOnboardRetailers: {
    isLoading: false,
    data: null,
  },
  leadSavedRetailers: {
    isLoading: false,
    data: null,
  },
};

export default function visitHistoryReducer(
  state: any = initialState,
  action: VisitHistoryActionTypes
) {
  // Assigning data to respective states using switching action types

  switch (action.type) {
    // Visit History Stats
    case GET_STATS:
      return {
        ...state,
        stats: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Visit History List
    case GET_LIST:
      return {
        ...state,
        list: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Visit History Details
    case GET_DETAILS:
      return {
        ...state,
        details: {
          isLoading: action.loading,
          data: action.data,
        },
      };
    // Visit History Details - Beat Report Retailer List
    case GET_DETAILS_LIST:
      return {
        ...state,
        detailList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Visit History Detail - Store's Onboard Retailer List
    case GET_STORE_ONBOARD_RETAILER_LIST:
      return {
        ...state,
        storeOnboardRetailers: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Visit History Detail - Lead Saved Retailer List
    case GET_LEAD_SAVED_RETAILER_LIST:
      return {
        ...state,
        leadSavedRetailers: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Returning all states by defaults
    default:
      return { ...state };
  }
}
