// Improve Functions

export const setCookie = (name: string, value: string, days?: number) => {
  const date = new Date();

  if (days) {
    // set it exipre in given days
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  } else {
    // set it exipre in 3 days
    date.setTime(date.getTime() + 3 * 24 * 60 * 60 * 1000);
  }

  // set it
  document.cookie =
    name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
};

export const getCookie = (name: string) => {
  const value = "; " + document.cookie;
  const stack = value.split("; " + name + "=");

  if (stack.length === 2) {
    return stack.pop()?.split(";").shift();
  }
};

export const deleteCookie = (name: string) => {
  // set it
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export default {
  set: setCookie,
  get: getCookie,
  delete: deleteCookie,
};
