import {
  NEW_BDE,
  ASSIGNED_RETAILER,
  ASSIGNED_AREAS,
  ASSIGNED_RETAILER_LIST,
  DELETE_RETAILER,
  DELETE_CATEGORY,
  DELETE_PINCODE,
  DELETE_PINCODE_AND_CATEGORY,
  BDE_LIST,
  RETAILERS_LIST,
  BDE_AREAS,
  BDE_SEGMENTS,
  GET_BDE_PROFILE,
  GET_LINKED_RETAILER,
  BDE_PINCODES,
  IS_ALL_SELECTED,
  MY_BDE,
  ASSIGNED_PINCODES,
  GET_BLOOD_GROUP_OPTIONS,
  GET_BDE_PERFORMANCE,
  GET_UNMASKED_DATA,
  GET_ASSIGNED_RETAILERS_OF_PARTICULAR_BDE,
  RETAILERS_LIST_LOADING,
  BDE_LOADING,
} from "store/constants/BDEManagement";

import { LOCATION_CHANGE } from "store/constants/changeLocation";
import {
  uniqueArray,
  uniqPinCodes,
  uniqRetailers,
  uniqAllSelectedPinsAndSegs,
} from "utils/array";
import { BdeActionType, IBDEState } from "types/bdeTypes";

const inititalState = {
  newBDE: {},
  myBDE: { results: [] },
  assignedRetailers: [],
  assignedAreas: [],
  assignedPincodes: [],
  assignedAllPinsAndSegs: [],
  assignedCategory: [],
  assignedPins: [],
  assignedPincodesAndCategory: [],
  removedMerchantIds: [],
  assignedRetailerList: { results: [] },
  getAssignedRetailerListOfParticularBDE: null,
  bdeList: {},
  retailers: { results: [] },
  areas: [],
  segments: [],
  bdeProfile: {},
  linkedRetailers: { results: [] },
  pincodes: { results: [] },
  isAllSelected: false,
  bloodGroups: [],
  BDEPerformance: {},
  bdeUnmaskedData: {},
  retailers_loading: false,
  bde_loading: false,
};

export default function bdeReducer(
  state: IBDEState = inititalState,
  action: BdeActionType
) {
  const assignedRetailersData = state.assignedRetailers;

  switch (action.type) {
    case NEW_BDE:
      return { ...state, newBDE: action.data };

    case MY_BDE:
      let bdeData = action.data;
      if (state.myBDE) {
        bdeData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.myBDE?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, myBDE: bdeData };

    case ASSIGNED_RETAILER_LIST:
      return { ...state, assignedRetailerList: action.data };

    case ASSIGNED_RETAILER:
      let newSelected: any[] = [...assignedRetailersData];
      let selectedPincodes = [...state.assignedPincodes];

      if (Array.isArray(action.data)) {
        newSelected.push(...action.data);

        action.data.map((item: any) => {
          selectedPincodes.push({
            slug: item.pincode,
            name: item.pincode,
            pincode: item.pincode,
          });
        });
      } else {
        newSelected.push(action.data);
        selectedPincodes.push({
          slug: action.data?.pincode,
          name: action.data?.pincode,
          pincode: action.data?.pincode,
        });
      }

      const uniqSelected = uniqRetailers(newSelected);

      // Selected Pins
      // let selectedPincodes = [...state.assignedPincodes];

      const uniquePinCodes = uniqPinCodes(selectedPincodes);

      return {
        ...state,
        assignedRetailers: uniqSelected,
        assignedPincodes: uniquePinCodes,
      };

    case ASSIGNED_AREAS:
      // Selected Areas
      let selected: string[] = [...state.assignedAreas];

      if (state.isAllSelected) {
        selected.push(action.data.slug);
      } else {
        const index = selected.indexOf(action.data.slug);
        if (index > -1) selected.splice(index, 1);
      }

      const uniqArr = uniqueArray(selected);

      //  Selected Pincodes from the area
      let selectedPins: any = [...state.assignedPincodes];
      selectedPins.push(...action.data.pincodes);
      const uniqPins = uniqPinCodes(selectedPins);
      return {
        ...state,
        assignedAreas: uniqArr,
        assignedPincodes: uniqPins,
      };

    case ASSIGNED_PINCODES:
      let selectedPin: any = [...state.assignedPincodes];
      let isAllPinsAndSegments = [...state.assignedAllPinsAndSegs];
      let selectedCategory = [...state.assignedCategory];
      let selectedPincode = [...state.assignedPins];
      let selectedPincodeAndCategory = [...state.assignedPincodesAndCategory];

      if (state.isAllSelected) {
        if (action.data.category && action.data.pincode) {
          // const grabPincodes = state.assignedRetailers.map(
          //   (item: any) => item.pincode
          // );

          // const generatedArray = grabPincodes.map((item: any) => ({
          //   category: action.data.category,
          //   pincode: action.data.category ? "" : item,
          // }));

          isAllPinsAndSegments.push({
            pincode: action.data.pincode,
            category: action.data.category,
          });

          selectedPincodeAndCategory.push({
            pincode: action.data.pincode,
            category: action.data.category,
          });

          // state.assignedRetailers.map((item: any) =>
          //   selectedPin.push({
          //     slug: item.pincode,
          //     name: item.pincode,
          //     pincode: item.pincode,
          //   })
          // );
        } else if (action.data.category) {
          isAllPinsAndSegments.push({
            pincode: "",
            category: action.data.category,
          });

          selectedCategory.push({
            pincode: "",
            category: action.data.category,
          });
        } else {
          isAllPinsAndSegments.push(action.data);

          selectedPincode.push({
            pincode: action.data.pincode,
            category: "",
          });

          // selectedPin.push({
          //   slug: action.data.pincode,
          //   name: action.data.pincode,
          //   pincode: action.data.pincode,
          // });
        }
      } else {
        if (action.data.category && action.data.pincode) {
          const indexOfPinAndCateInASPC = selectedPincodeAndCategory.findIndex(
            (item: any) =>
              item.pincode === action.data.pincode &&
              item.category === action.data.category
          );

          if (indexOfPinAndCateInASPC > -1)
            selectedPincodeAndCategory.splice(indexOfPinAndCateInASPC, 1);

          const indexOfPinAndCateInAAPC = isAllPinsAndSegments.findIndex(
            (item: any) =>
              item.pincode === action.data.pincode &&
              item.category === action.data.category
          );

          if (indexOfPinAndCateInAAPC > -1)
            isAllPinsAndSegments.splice(indexOfPinAndCateInAAPC, 1);
        } else if (action.data.category) {
          const indexOfCategory = selectedCategory.findIndex(
            (item) => item.category === action.data.category
          );

          if (indexOfCategory > -1) selectedCategory.splice(indexOfCategory);

          const indexOfCate = isAllPinsAndSegments.findIndex(
            (item) => item.category === action.data.category
          );
          // const grabSegments = isAllPinsAndSegments.filter(
          //   (item) => item.category === action.data.category
          // );
          if (indexOfCate > -1) isAllPinsAndSegments.splice(indexOfCate);
        } else {
          const indexOfPin = selectedPincode.findIndex(
            (item) => item.category === action.data.category
          );

          if (indexOfPin > -1) selectedPincode.splice(indexOfPin);

          const indexOfPincode = isAllPinsAndSegments.findIndex(
            (item) => item.pincode === action.data.pincode
          );

          if (indexOfPincode > -1)
            isAllPinsAndSegments.splice(indexOfPincode, 1);
        }
        // selectedPin.push(action.data);

        // Removeing based on segments (category)

        // if (indexOfCate > -1)
        //   isAllPinsAndSegments.splice(indexOfCate, 1);
      }

      const uniqPin = uniqPinCodes(selectedPin);

      // const uniqIsAllPinsAndSegments =
      //   uniqAllSelectedPinsAndSegs(isAllPinsAndSegments);

      return {
        ...state,
        assignedPincodes: uniqPin,
        assignedAllPinsAndSegs: isAllPinsAndSegments,
        assignedCategory: selectedCategory,
        assignedPins: selectedPincode,
        assignedPincodesAndCategory: selectedPincodeAndCategory,
      };

    // Get assigned retailer of particular BDE
    case GET_ASSIGNED_RETAILERS_OF_PARTICULAR_BDE:
      return {
        ...state,
        getAssignedRetailerListOfParticularBDE: action.data,
      };

    case DELETE_RETAILER:
      let filterData = assignedRetailersData.filter(
        (item: any) => item.merchant_id !== action.data
      );

      // Store removed merchant ids
      let removedIds: string[] = [...state.removedMerchantIds];
      removedIds.push(action.data);

      let uniqRemovedIds = uniqueArray(removedIds);

      return {
        ...state,
        assignedRetailers: filterData,
        removedMerchantIds: uniqRemovedIds,
      };

    case DELETE_CATEGORY:
      let filterSeg = [...state.assignedAllPinsAndSegs];

      let filterCate = state.assignedCategory.filter(
        (item: any) => item.category !== action.data.category
      );

      const indexSeg = filterSeg.findIndex(
        (item: any) =>
          item.category === action.data.category &&
          item.pincode === action.data.pincode
      );

      if (indexSeg > -1) filterSeg.splice(indexSeg, 1);

      return {
        ...state,
        assignedCategory: filterCate,
        assignedAllPinsAndSegs: filterSeg,
      };

    case DELETE_PINCODE:
      let filterPincode = [...state.assignedAllPinsAndSegs];

      let filterPin = state.assignedPins.filter(
        (item: any) => item.pincode !== action.data.pincode
      );

      const indexPincode = filterPincode.findIndex(
        (item: any) =>
          item.pincode === action.data.pincode &&
          item.category === action.data.category
      );

      if (indexPincode > -1) filterPincode.splice(indexPincode, 1);

      return {
        ...state,
        assignedPins: filterPin,
        assignedAllPinsAndSegs: filterPincode,
      };

    case DELETE_PINCODE_AND_CATEGORY:
      let filterInAssignedPinOrCate = [...state.assignedPincodesAndCategory];
      let filterInAssignedAllPinOrCate = [...state.assignedAllPinsAndSegs];

      let indexInAssignedPinOrCate = filterInAssignedPinOrCate.findIndex(
        (item: any) =>
          item.pincode === action.data.pincode &&
          item.category === action.data.category
      );

      if (indexInAssignedPinOrCate > -1)
        filterInAssignedPinOrCate.splice(indexInAssignedPinOrCate, 1);

      let indexInAllPinOrCate = filterInAssignedAllPinOrCate.findIndex(
        (item: any) =>
          item.pincode === action.data.pincode &&
          item.category === action.data.category
      );

      if (indexInAllPinOrCate > -1)
        filterInAssignedAllPinOrCate.splice(indexInAllPinOrCate, 1);

      return {
        ...state,
        assignedPincodesAndCategory: filterInAssignedPinOrCate,
        assignedAllPinsAndSegs: filterInAssignedAllPinOrCate,
      };

    case IS_ALL_SELECTED: {
      let isAllSelectedRetailer: any[] = [...state.assignedRetailers];
      // isAllSelectedRetailer.push(...state.retailers.results)

      if (action.data) {
        isAllSelectedRetailer.push(...state.retailers.results);
      } else {
        const ids = state.retailers.results.map(
          (item: any) => item.merchant_id
        );
        let removed = isAllSelectedRetailer.filter(
          (item) => !ids.includes(item.merchant_id)
        );
        isAllSelectedRetailer = removed;
      }

      const uniqIsAllSelectedRetailers = uniqRetailers(isAllSelectedRetailer);

      return {
        ...state,
        isAllSelected: action.data,
        // assignedRetailers: uniqIsAllSelectedRetailers,
      };
    }

    case BDE_LIST:
      return { ...state, bdeList: action.data };

    case BDE_SEGMENTS:
      return { ...state, segments: action.data };

    case BDE_PINCODES:
      let pincodesData = action.data;
      if (state.pincodes) {
        pincodesData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.pincodes?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, pincodes: pincodesData };

    case BDE_AREAS:
      return { ...state, areas: action.data };

    case RETAILERS_LIST:
      let retailersData = action.data;
      if (state.retailers) {
        retailersData = {
          next: action.data?.next,
          previous: action.data?.previous,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.retailers?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, retailers: retailersData };

    case RETAILERS_LIST_LOADING:
      return { ...state, retailers_loading: action.data };

    case BDE_LOADING:
      return { ...state, bde_loading: action.data };

    case GET_BDE_PROFILE:
      return { ...state, bdeProfile: action.data };

    case GET_LINKED_RETAILER:
      return { ...state, linkedRetailers: action.data };

    case GET_BLOOD_GROUP_OPTIONS:
      return { ...state, bloodGroups: action.data };
    case GET_BDE_PERFORMANCE:
      return { ...state, BDEPerformance: action.data };

    case GET_UNMASKED_DATA:
      return { ...state, bdeUnmaskedData: action.data };

    case LOCATION_CHANGE:
      return inititalState;

    default:
      return state;
  }
}
