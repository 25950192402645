import {
  FeatureSwitchActionTypes,
  GET_FEATURE_SWITCH_LIST,
} from "store/constants/FeatureSwitch";

const initialState = {
  featureSwitchList: {
    isLoading: false,
    data: [],
  },
};

export default function featureSwitchReducer(
  state: any = initialState,
  action: FeatureSwitchActionTypes
) {
  switch (action.type) {
    case GET_FEATURE_SWITCH_LIST:
      return {
        ...state,
        featureSwitchList: {
          isLoading: action.loading,
          data: action.data,
        },
      };
    default:
      return { ...state };
  }
}
