// ASM
export const MY_ASM_LIST = "MY_ASM_LIST";
export const MY_ASM_MANAGER_LIST = "MY_ASM_MANAGER_LIST";
// Onboarding
export const ASM_CREATED = "ASM_CREATED";
export const CSM_CREATED = "CSM_CREATED";
export const ASSIGNED_REGIONS = "ASSIGNED_REGIONS";
export const ASSIGNED_AREA = "ASSIGNED_AREA";
export const ASSIGNED_PINCODES_RM = "ASSIGNED_PINCODES_RM";
export const DELETE_PINCODES = "DELETE_PINCODES";
export const ASSIGNED_ASM_RM = "ASSIGNED_ASM_RM";
export const DELETE_ASM = "DELETE_ASM";
export const GET_TOP_ASM_LIST = "GET_TOP_ASM_LIST";
export const GET_TOP_CSM_LIST = "GET_TOP_CSM_LIST";
export const DELETE_CITIES = "DELETE_CITIES";
export const IS_ALL_PINCODES_SELECTED = "IS_ALL_PINCODES_SELECTED";
export const ASM_PERFORMANCE = "ASM_PERFORMANCE";
export const FETCH_ASM_PROFILE = "FETCH_ASM_PROFILE";
export const FETCH_ASM_STATS = "FETCH_ASM_STATS";
export const FETCH_ASM_STATS_V2 = "FETCH_ASM_STATS_V2";
export const ASM_LIST_LOADING = "ASM_LIST_LOADING";
// Loading
export const IS_LOADING = "IS_LOADING";

//CSM
export const FETCH_CSM_PROFILE = "FETCH_CSM_PROFILE";
export const CSM_PERFORMANCE = "CSM_PERFORMANCE";
export const CSM_AND_ASM_LIST = "CSM_AND_ASM_LIST";
export const CSM_AND_ASM_LIST_LOADING = "CSM_AND_ASM_LIST_LOADING";
export const GET_CSM_LINKED_ASM = "GET_RM_LINKED_ASM";
