import { Box, CircularProgress } from "@mui/material";
import Logo from "components/Logo";
import { LoaderStyles } from "styles/generic";

const SuspenseLoader = () => {
  const classes = LoaderStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.logo}>
        <Logo />
      </Box>
      <CircularProgress size={20} disableShrink />
    </Box>
  );
};

export default SuspenseLoader;
