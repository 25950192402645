import { LOCATION_CHANGE } from "store/constants/changeLocation";

// ============ REPORT CARD ==============

export interface ILocationChangeActionType {
  type: typeof LOCATION_CHANGE;
  data?: any;
}

export const GET_DC_LIST = "GET_DC_LIST";
export interface IGetDCListActionTypes {
  type: typeof GET_DC_LIST;
  data?: any;
  loading?: boolean;
}
export const DC_LIST_LOADING = "DC_LIST_LOADING";
export interface IDCListLoadingActionTypes {
  type: typeof DC_LIST_LOADING;
  loading?: boolean;
}

export const UPDATE_DC_STATUS = "UPDATE_DC_STATUS";
export interface IUpdateDCStatusActionTypes {
  type: typeof UPDATE_DC_STATUS;
  data?: any;
}

// ============ REPORT CARD ==============

export const GET_RETAILERS_TAGGED_LIST = "GET_RETAILERS_TAGGED_LIST";
export interface IGetRetailersTaggedActionTypes {
  type: typeof GET_RETAILERS_TAGGED_LIST;
  data?: any;
  loading?: boolean;
}
export const GET_DC_REPORT_LIST = "GET_DC_REPORT_LIST";
export interface IGetDCReportActionTypes {
  type: typeof GET_DC_REPORT_LIST;
  data?: any;
  loading?: boolean;
}

export const GET_FEEDBACK_RECEIVED = "GET_FEEDBACK_RECEIVED";
export interface IGetFeedbackReceivedActionTypes {
  type: typeof GET_FEEDBACK_RECEIVED;
  data?: any;
  loading?: boolean;
}

// ============ ADD NEW DISCUSSION CARD ==============
export const GET_CONDITION_OPTIONS = "GET_CONDITION_OPTIONS";
export const GET_SUB_CONDITION_OPTIONS = "GET_SUB_CONDITION_OPTIONS";
export const GET_STORE_TYPE_OPTIONS = "GET_STORE_TYPE_OPTIONS";
export const SHOW_TAGGED_RETAILER_COUNT = "SHOW_TAGGED_RETAILER_COUNT";
export const UPLOAD_RETAILER_LIST = "UPLOAD_RETAILER_LIST";
export const GET_FEEDBACK_OPTIONS = "GET_FEEDBACK_OPTIONS";
export const GET_SEQUENCE_OPTIONS = "GET_SEQUENCE_OPTIONS";

export interface IGetConditionOptionsActionType {
  type: typeof GET_CONDITION_OPTIONS;
  data?: any;
  loading?: any;
}

export interface IGetSubConditionOptionsActionType {
  type: typeof GET_SUB_CONDITION_OPTIONS;
  data?: any;
  loading?: any;
}
export interface IGetStoreTypeOptionsActionType {
  type: typeof GET_STORE_TYPE_OPTIONS;
  data?: any;
  loading?: any;
}

export interface IShowTaggedRetailerCountActionType {
  type: typeof SHOW_TAGGED_RETAILER_COUNT;
  data?: any;
}

export interface IUploadRetailerActionType {
  type: typeof UPLOAD_RETAILER_LIST;
  data?: any;
  isLoading: boolean;
}
export interface IGetFeebackOptionsActionType {
  type: typeof GET_FEEDBACK_OPTIONS;
  data?: any;
}

export interface IGetSequenceOptionsActionType {
  type: typeof GET_SEQUENCE_OPTIONS;
  data?: any;
}

export type DCListActionTypes =
  | IGetDCListActionTypes
  | IDCListLoadingActionTypes;

export type DCReportActionTypes =
  | IGetRetailersTaggedActionTypes
  | IGetDCReportActionTypes
  | IGetFeedbackReceivedActionTypes;

export type AddNewDCActionTypes =
  | IGetConditionOptionsActionType
  | IGetStoreTypeOptionsActionType
  | IShowTaggedRetailerCountActionType
  | IGetSubConditionOptionsActionType
  | IUploadRetailerActionType
  | IGetFeebackOptionsActionType
  | IGetSequenceOptionsActionType;

export type DCActionTypes =
  | DCListActionTypes
  | AddNewDCActionTypes
  | DCReportActionTypes
  | ILocationChangeActionType;
