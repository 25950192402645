export const NDR_DOWNLOAD = "NDR_DOWNLOAD";

export interface INDRDownloadActionTypes {
  type: typeof NDR_DOWNLOAD;
  data: any;
}

/* =============================
  NDR Table 
 =============================== */

// NDR List
export const NDR_LIST = "NDR_LIST";
export interface INDRListActionTypes {
  type: typeof NDR_LIST;
  data?: any;
  loading?: boolean;
}

// NDR Details
export const NDR_DETAIL = "NDR_DETAIL";
export interface INDRDetailActionTypes {
  type: typeof NDR_DETAIL;
  data?: any;
  loading?: boolean;
}

export type NDRTableActionTypes = INDRListActionTypes | INDRDetailActionTypes;

export type NDRActionTypes = INDRDownloadActionTypes | NDRTableActionTypes;
