// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-outer {
  ul {
    padding: revert !important;
  }
  p {
    margin: 12px 0;
  }
  table,
  td,
  th {
    border: 1px solid #c3c3c3;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
}
.container .inner {
  margin: 0 auto;
  max-width: 1120px;
  padding: 20px;
}
.container .inner .title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.3px;
  margin-bottom: 32px;
  text-align: center;
}
.container .inner h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .container-outer {
    font-size: 14px;
    line-height: 20px;
  }
  .container .inner .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .container .inner h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/PrivacyPolicy/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,cAAc;EAChB;EACA;;;IAGE,yBAAyB;EAC3B;EACA;IACE,WAAW;IACX,yBAAyB;EAC3B;AACF;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".container-outer {\n  ul {\n    padding: revert !important;\n  }\n  p {\n    margin: 12px 0;\n  }\n  table,\n  td,\n  th {\n    border: 1px solid #c3c3c3;\n  }\n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n}\n.container .inner {\n  margin: 0 auto;\n  max-width: 1120px;\n  padding: 20px;\n}\n.container .inner .title {\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 36px;\n  letter-spacing: -0.3px;\n  margin-bottom: 32px;\n  text-align: center;\n}\n.container .inner h2 {\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n  letter-spacing: -0.3px;\n  margin-bottom: 16px;\n}\n@media screen and (max-width: 767px) {\n  .container-outer {\n    font-size: 14px;\n    line-height: 20px;\n  }\n  .container .inner .title {\n    font-size: 20px;\n    line-height: 24px;\n    margin-bottom: 16px;\n  }\n  .container .inner h2 {\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
