/* =============================
  Pincode Table 
 =============================== */

// Pincode List
export const PINCODE_LIST = "PINCODE_LIST";
export interface IPincodeListActionTypes {
  type: typeof PINCODE_LIST;
  data?: any;
  loading?: boolean;
}

export type PincodeTableActionTypes = IPincodeListActionTypes;

export type PincodeActionTypes = PincodeTableActionTypes;
