// constants and action types
import {
  AttendanceActionTypes,
  GET_ATTENDANCE_STATS,
  GET_ATTENDANCE_LIST_WITHOUT_FILTER,
  GET_ATTENDANCE_LIST_WITH_FILTER,
  GET_BDE_PROFILE_DETAIL,
  GET_CALENDAR,
} from "store/constants/attendance";

// Initial States

// TODO - Have to define valid interface for initial states
const initialState = {
  stats: {
    isLoading: false,
    data: null,
  },
  listWithoutFilter: {
    isLoading: false,
    data: null,
  },
  listWithFilter: {
    isLoading: false,
    data: null,
  },
  profile: {
    isLoading: false,
    data: null,
  },
  calendar: {
    isLoading: false,
    data: null,
  },
};

export default function attendanceReducer(
  state: any = initialState,
  action: AttendanceActionTypes
) {
  // Assigning data to respective states using switching action types

  switch (action.type) {
    // Attendance Stats
    case GET_ATTENDANCE_STATS:
      return {
        ...state,
        stats: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Attendance List without Filter
    case GET_ATTENDANCE_LIST_WITHOUT_FILTER:
      return {
        ...state,
        listWithoutFilter: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Attendance List with Filter
    case GET_ATTENDANCE_LIST_WITH_FILTER:
      return {
        ...state,
        listWithFilter: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Profile of particular BDE
    case GET_BDE_PROFILE_DETAIL:
      return {
        ...state,
        profile: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Calendar States
    case GET_CALENDAR:
      return {
        ...state,
        calendar: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Returning all states by defaults
    default:
      return { ...state };
  }
}
