import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import cookies from "utils/cookies";
import { USER_LOGIN_SUCCESS } from "store/constants/auth";
import "./index.css";
import Appv2 from "./Appv2";
import FosAlert from "components/generic/FosAlert";
import "react-datepicker/dist/react-datepicker.css";

// Removing Mui prefix from the class name of the component
// ClassNameGenerator.configure((componentName: any) =>
//   componentName.replace("Mui", "")
// );

/* ==============================
 * 
 * 26 MAY RELEASED
 * 
 * Contains -----
 * 
 * Country Head Dahboard 
 * Regional Manager Dashboard 
 * Area Sales Manager Dashboard
 * 
 ================================  */

/** ============================
 * Checking if user already logged in or not.
 * If user already logged In -> Tell store we have access token 
 * to fetch resources.
 =============================== */

const __IS_SERVER__ = typeof window === "undefined";

if (!__IS_SERVER__) {
  const access = cookies.get("SECURE-TOKEN");
  const exp = cookies.get("__SECURE-EXPID-NS__");
  const payload = {
    token: access,
    exp: exp,
  };
  if (access) {
    store.dispatch<any>({ type: USER_LOGIN_SUCCESS, data: payload });
  }
}

if (process.env.REACT_APP_FOS_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <FosAlert />
        <Appv2 />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
