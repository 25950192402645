// Motivator reducer (Target and Achievement Module)

// Constants and Action Types
import {
  MotivatorActionType,
  GET_ALL_TARGETS,
  GET_ALL_CAMPAIGNS,
  GET_TARGET_LIST,
  UPLOAD_TARGETS_SHEET,
  GET_TARGET_BLOCKS_FOR_CAMPAIGN_INFO_FORM,
  CREATE_CAMPAIGN_INFORMATION,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_DETAILS_BDE_LINKED,
  GET_CAMPAIGN_DETAILS_ASM_LINKED,
  GET_CAMPAIGN_DETAILS_BDE_LINKED_STATS,
  GET_CAMPAIGN_DETAILS_BDE_LINKED_INCENTIVES,
  GET_EDIT_CAMPAIGN_DETAILS,
  GET_EDIT_CAMPAIGN_CONDITIONS,
  GET_EDIT_CAMPAIGN_ELIGIBILITY_CONDITIONS,
} from "store/constants/motivator";

// Initial States

// TODO - Have to define interface for feedback initial states
const initialState = {
  targets: {
    isLoading: false,
    data: null,
  },
  targetList: {
    isLoading: false,
    data: null,
  },
  targetBlocks: {
    results: [],
  },
  uploadTargets: {
    isLoading: false,
    data: null,
  },
  campaigns: {
    isLoading: false,
    data: null,
  },
  createCampaignInfo: {
    isLoading: false,
    data: null,
  },
  editCampaignDetails: {
    isLoading: false,
    data: null,
  },
  editCampaignConditions: {
    isLoading: false,
    data: null,
  },
  editCampaignEligibilityConditions: {
    isLoading: false,
    data: null,
  },
  campaignList: {
    isLoading: false,
    data: null,
  },
  campaignBDELinked: {
    isLoading: false,
    data: null,
  },
  campaignBDELinkedStats: {
    isLoading: false,
    data: null,
  },
  campaignBDELinkedIncentive: {
    isLoading: false,
    data: null,
  },
  campaignASMLinked: {
    isLoading: false,
    data: null,
  },
};

export default function motivatorReducer(
  state: any = initialState,
  action: MotivatorActionType
) {
  // Assigning data to respective states using switching action_types

  switch (action.type) {
    // Get All Targets
    case GET_ALL_TARGETS:
      return {
        ...state,
        targets: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get target list
    case GET_TARGET_LIST:
      return {
        ...state,
        targetList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get all target blocks for campaign information form
    case GET_TARGET_BLOCKS_FOR_CAMPAIGN_INFO_FORM:
      return {
        ...state,
        targetBlocks: action.data,
      };

    // Upload Targets
    case UPLOAD_TARGETS_SHEET:
      return {
        ...state,
        uploadTargets: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get All Campaigns
    case GET_ALL_CAMPAIGNS:
      return {
        ...state,
        campaigns: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Create campaign information
    case CREATE_CAMPAIGN_INFORMATION:
      return {
        ...state,
        createCampaignInfo: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get Edit Campaign Info
    case GET_EDIT_CAMPAIGN_DETAILS:
      return {
        ...state,
        editCampaignDetails: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get Edit Campaign Conditions
    case GET_EDIT_CAMPAIGN_CONDITIONS:
      return {
        ...state,
        editCampaignConditions: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get Edit Campaign Eligibility Conditions
    case GET_EDIT_CAMPAIGN_ELIGIBILITY_CONDITIONS:
      return {
        ...state,
        editCampaignEligibilityConditions: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get all campaign list
    case GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get BDE Linked -> Campaign Details
    case GET_CAMPAIGN_DETAILS_BDE_LINKED:
      return {
        ...state,
        campaignBDELinked: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get BDE Linked Stats -> Campaign Details
    case GET_CAMPAIGN_DETAILS_BDE_LINKED_STATS:
      return {
        ...state,
        campaignBDELinkedStats: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Get BDE Linked Incentive -> Campaign Details
    case GET_CAMPAIGN_DETAILS_BDE_LINKED_INCENTIVES:
      return {
        ...state,
        campaignBDELinkedIncentive: {
          isLoading: action.loading,
          data: action.data,
        },
      };
    // GET ASM Linked -> Campaign Details
    case GET_CAMPAIGN_DETAILS_ASM_LINKED:
      return {
        ...state,
        campaignASMLinked: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Returning all states by defaults
    default:
      return { ...state };
  }
}
