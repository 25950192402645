import {
  USER_LOGIN_SUCCESS,
  GET_USER_DETAILS,
  LOGOUT_SUCCESSFULL,
  HAS_PERMISSION,
  GET_ACCESS_URLS_OF_CURRENT_USERS,
  GET_PERMISSIONS_OF_CURRENT_USER,
  SET_SIDEBAR_ITEMS,
  SET_PERMISSIONS_V2,
} from "store/constants/auth";

import cookies from "utils/cookies";

interface Permission {
  data: any;
  isLoading: boolean;
}

interface AuthState {
  isAuthenticated: boolean;
  access_token: string | null;
  user: object | null;
  permissions: Permission | null;
}

interface Action {
  type: string;
  data: any;
  loading?: boolean;
  has_permisson: boolean;
}

const inititalState = {
  isAuthenticated: false,
  access_token: null,
  user: null,
  has_permission: true,
  accessUrls: null,
  sidebarItems: null,
  permissionsV2: {
    data: [],
    isLoading: false,
  },
  userLoading: null,
  permissions: {
    data: [],
    isLoading: false,
  },
};

export default function authReducer(
  state: any = inititalState,
  action: Action
) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      cookies.set("SECURE-TOKEN", action.data.token);
      cookies.set("__SECURE-EXPID-NS__", action.data.exp);
      return {
        ...state,
        isAuthenticated: true,
        access_token: action.data.token,
      };

    case LOGOUT_SUCCESSFULL:
      cookies.delete("SECURE-TOKEN");
      cookies.delete("__SECURE-EXPID-NS__");
      return {
        ...state,
        isAuthenticated: false,
      };

    case GET_USER_DETAILS:
      return {
        ...state,
        userLoading: action.loading,
        user: action.data,
      };

    case HAS_PERMISSION:
      return { ...state, has_permission: action.data };

    case GET_ACCESS_URLS_OF_CURRENT_USERS:
      return { ...state, accessUrls: action.data };

    case SET_SIDEBAR_ITEMS:
      return { ...state, sidebarItems: action.data };

    case SET_PERMISSIONS_V2:
      return { ... state, permissionsV2: action.data }

    case GET_PERMISSIONS_OF_CURRENT_USER:
      return {
        ...state,
        permissions: {
          data: action.data,
          isLoading: action.loading,
        },
      };

    default:
      return state;
  }
}
