//  constants and action types
import {
  MappingActionType,
  ASM_TO_PINCODE,
  ASM_TO_BDE,
  RETAILER_TO_BDE,
  LOCATION_CHANGE_MAPPING,
} from "store/constants/Mapping";

// initial states

// TODO - have to define interface for feedback initial states

const initialState = {
  asmtopincode: {
    isLoading: false,
    data: null,
    isSubmitted: false,
  },
  asmtobde: {
    isLoading: false,
    data: null,
    isSubmitted: false,
  },
  retailertobde: {
    isLoading: false,
    data: null,
    isSubmitted: false,
  },
};

export default function mappingReducer(
  state: any = initialState,
  action: MappingActionType
) {
  // assigning data to respective states using switching actiont_types

  switch (action.type) {
    // ASM to Pincode
    case ASM_TO_PINCODE:
      return {
        ...state,
        asmtopincode: {
          isLoading: action.loading,
          data: action.data,
          isSubmitted: action.isSubmitted,
        },
      };

    //  ASM to BDE
    case ASM_TO_BDE:
      return {
        ...state,
        asmtobde: {
          isLoading: action.loading,
          data: action.data,
          isSubmitted: action.isSubmitted,
        },
      };

    //  Retailer to BDE
    case RETAILER_TO_BDE:
      return {
        ...state,
        retailertobde: {
          isLoading: action.loading,
          data: action.data,
          isSubmitted: action.isSubmitted,
        },
      };

    // Reset all states
    case LOCATION_CHANGE_MAPPING:
      return initialState;

    // returning all states by default
    default:
      return { ...state };
  }
}
