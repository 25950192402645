import axios from "axios";
import cookies from "utils/cookies";

const AXIOS_HEADERS = {
  "Content-Type": "application/json",
  "x-fos-version": "web.1.0.0",
  Authorization: cookies.get("SECURE-TOKEN")
    ? `Token ${cookies.get("SECURE-TOKEN")}`
    : "",
};

// Impersonating headers
const AXIOS_HEADERS_WITH_IMPERSONATE = {
  "Content-Type": "application/json",
  "x-fos-version": "web.1.0.0",
  Authorization: cookies.get("SECURE-TOKEN")
    ? `Token ${cookies.get("SECURE-TOKEN")}`
    : "",

  "x-impersonate-for":
    cookies.get("SECURE-IMPSID-NS") !== undefined
      ? `${cookies.get("SECURE-IMPSID-NS")}`
      : "",
};

export default axios.create({
  baseURL: process.env.REACT_APP_FOS_BACKEND_URL + "/apis/",
  headers: cookies.get("SECURE-IMPSID-NS")
    ? AXIOS_HEADERS_WITH_IMPERSONATE
    : AXIOS_HEADERS,
  timeout: 30000,
});

export const axiosV2 = axios.create({
  baseURL: process.env.REACT_APP_FOS_BACKEND_URL_V2 + "/disha/",
  headers: cookies.get("SECURE-IMPSID-NS")
    ? AXIOS_HEADERS_WITH_IMPERSONATE
    : AXIOS_HEADERS,
  timeout: 30000,
});
