/* =============================
  Feedback Stats/Analytics
 =============================== */

export const GET_FEEDBACK_STATS = "GET_FEEDBACK_STATS";

export interface IGetFeedbackStatsActionType {
  type: typeof GET_FEEDBACK_STATS;
  data?: any;
  loading?: boolean;
}

// export interface IGetFeedbackStatsData extends IGetFeedbacksStatsType  {
//   data: any
// }

// export interface IGetFeedbackStatsLoading extends IGetFeedbacksStatsType {
//   loading: boolean
// }

// export type IGetFeedbacksStatsActionType = IGetFeedbackStatsData | IGetFeedbackStatsLoading;

/* =============================
  Feedback List
 =============================== */

export const GET_FEEDBACK_LIST = "GET_FEEDBACK_LIST";

export interface IGetFeedbackListActionType {
  type: typeof GET_FEEDBACK_LIST;
  data?: any;
  loading?: boolean;
}

export const GET_FILTER_TAGS_LIST = "GET_FILTER_TAGS_LIST";

export interface IGetFilterTagsListActionType {
  type: typeof GET_FILTER_TAGS_LIST;
  data?: any;
  loading?: boolean;
}

export type FeedbackActionType =
  | IGetFeedbackStatsActionType
  | IGetFeedbackListActionType
  | IGetFilterTagsListActionType;
