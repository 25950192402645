import { forwardRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch } from "react-redux";
import auth from "store/actions/auth";
import Warn from "assets/warn.png";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckPermissionAccess() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(true);

  const handleClose = () => {
    dispatch(auth.logOut());
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event: any, reason: any) => {
          if (reason && reason == "backdropClick") return;
        }}
        maxWidth="xs"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <img src={Warn} alt="warning" width="24px" />
              <Typography variant="h5" sx={{ ml: 1 }}>
                You don't have permission to access this page
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This page is not accessible. You might not have permission to use
            this page resource. Click to <b>Logout</b> to login with another
            account.
          </Typography>
          <Box
            mt={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              fullWidth
              sx={{ ml: 1 }}
              onClick={handleClose}
            >
              Logout
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
