export const ASM_TO_PINCODE = "ASM_TO_PINCODE";
export const ASM_TO_BDE = "ASM_TO_BDE";
export const RETAILER_TO_BDE = "RETAILER_TO_BDE";
export const LOCATION_CHANGE_MAPPING = "LOCATION_CHANGE_MAPPING";

export interface ASMtoPincodeActionType {
  type: typeof ASM_TO_PINCODE;
  loading?: boolean;
  data?: any;
  isSubmitted?: boolean;
}

export interface ASMtoBDEActionType {
  type: typeof ASM_TO_BDE;
  loading?: boolean;
  data?: any;
  isSubmitted?: boolean;
}

export interface RetailertoBDEActionType {
  type: typeof RETAILER_TO_BDE;
  loading?: boolean;
  data?: any;
  isSubmitted?: boolean;
}

export interface LocationChangeActionType {
  type: typeof LOCATION_CHANGE_MAPPING;
  data: any;
}

export type MappingActionType =
  | ASMtoPincodeActionType
  | ASMtoBDEActionType
  | RetailertoBDEActionType
  | LocationChangeActionType;
