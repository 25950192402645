// Attendance constants

/* =============================
  Attendance Stats
 =============================== */
export const GET_ATTENDANCE_STATS = "GET_ATTENDANCE_STATS";
export interface IGetAttendanceStats {
  type: typeof GET_ATTENDANCE_STATS;
  data?: any;
  loading?: boolean;
}

/* =============================
  Attendance List
 =============================== */
export const GET_ATTENDANCE_LIST_WITHOUT_FILTER =
  "GET_ATTENDANCE_LIST_WITHOUT_FILTER";
export const GET_ATTENDANCE_LIST_WITH_FILTER =
  "GET_ATTENDANCE_LIST_WITH_FILTER";

export interface IGetAttendanceListWithoutFilterActionType {
  type: typeof GET_ATTENDANCE_LIST_WITHOUT_FILTER;
  data?: any;
  loading?: boolean;
}
export interface IGetAttendanceListWithFilterActionType {
  type: typeof GET_ATTENDANCE_LIST_WITH_FILTER;
  data?: any;
  loading?: boolean;
}

export type AttendanceListActionTypes =
  | IGetAttendanceListWithoutFilterActionType
  | IGetAttendanceListWithFilterActionType;

// Attendance in Detail

/* =============================
  Attendance Stats
 =============================== */

export const GET_BDE_PROFILE_DETAIL = "GET_BDE_PROFILE_DETAIL";
export interface IGetBDEProfileDetailActionType {
  type: typeof GET_BDE_PROFILE_DETAIL;
  data?: any;
  loading?: boolean;
}

/* =============================
  Attendance Calendar
 =============================== */
export const GET_CALENDAR = "GET_CALENDAR";
export interface IGetCalendarDataActionType {
  type: typeof GET_CALENDAR;
  data?: any;
  loading?: any;
}
export type BDEDetailActionTypes =
  | IGetBDEProfileDetailActionType
  | IGetCalendarDataActionType;

export type AttendanceActionTypes =
  | IGetAttendanceStats
  | AttendanceListActionTypes
  | BDEDetailActionTypes;
