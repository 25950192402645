import {
  GET_PINCODE,
  GET_ASSIGNED_PINCODE,
  GET_AREAS,
  GET_REGIONS,
  GET_STATES,
  GET_CITIES,
  GET_MY_ASM_AREAS,
  GET_PINCODE_LOADING,
  PINCODE_LIST,
  UPLOAD_PINCODE,
  ACTIVE_PINCODE_LIST,
  GET_REGION_DATA,
} from "store/constants/geographies";

import { LOCATION_CHANGE } from "store/constants/changeLocation";
import { IGEOStates, GeoActionType } from "types/geoTypes";

const initialStates = {
  pincodes: {
    results: [],
  },
  rmAssignedPins: {
    results: [],
  },
  areas: [],
  regions: [],
  states: {
    results: [],
  },
  pincodeList: {
    isLoading: false,
    data: null,
  },
  activePincodeList: {
    isLoading: false,
    data: null,
  },
  uploadPincode: {
    isLoading: false,
    data: null,
    isSubmitted: false,
  },

  cities: {
    results: [],
  },

  regionData: {
    isLoading: false,
    data: null,
  },

  myASMAreas: {},
  loading: false,
};

export default function geoReducer(
  state: IGEOStates = initialStates,
  action: GeoActionType
) {
  switch (action.type) {
    case GET_PINCODE:
      let pincodesData = action.data;
      if (state.pincodes) {
        pincodesData = {
          next: action.data?.next,
          previous: action.data?.previous,
          page_size: action.data?.page_size,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.pincodes?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, pincodes: pincodesData };

    case GET_ASSIGNED_PINCODE:
      let assignedPincode = action.data;
      if (state.rmAssignedPins) {
        assignedPincode = {
          next: action.data?.next,
          previous: action.data?.previous,
          page_size: action.data?.page_size,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.rmAssignedPins?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, rmAssignedPins: assignedPincode };

    case GET_PINCODE_LOADING:
      return { ...state, loading: action.data };

    case GET_AREAS:
      return { ...state, areas: action.data };

    case GET_REGIONS:
      return { ...state, regions: action.data };

    case GET_STATES:
      let statesData = action.data;
      if (state.states) {
        statesData = {
          next: action.data?.next,
          previous: action.data?.previous,
          page_size: action.data?.page_size,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.states?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, states: statesData };

    case GET_CITIES:
      let citiesData = action.data;
      if (state.cities) {
        citiesData = {
          next: action.data?.next,
          previous: action.data?.previous,
          page_size: action.data?.page_size,
          total_count: action.data?.total_count,
          results: action.data?.previous
            ? [...state.cities?.results, ...action.data?.results]
            : [...action.data?.results],
        };
      }
      return { ...state, cities: citiesData };

    case GET_MY_ASM_AREAS:
      return { ...state, myASMAreas: action.data };

    case LOCATION_CHANGE:
      return initialStates;

    // Pincode List
    case PINCODE_LIST:
      return {
        ...state,
        pincodeList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    // Active Pincode List
    case ACTIVE_PINCODE_LIST:
      return {
        ...state,
        activePincodeList: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    //  Upload Pincode
    case UPLOAD_PINCODE:
      return {
        ...state,
        uploadPincode: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    case GET_REGION_DATA:
      return {
        ...state,
        regionData: {
          isLoading: action.loading,
          data: action.data,
        },
      };

    default:
      return state;
  }
}
