import { combineReducers } from "redux";
import authReducer from "./auth";
import bdeReducer from "./BDEManagement";
import retailerReducer from "./RetailerManagement";
import regionalManagerReducer from "./RegionalManager";
import geoReducer from "./geographies";
import asmDashboardReducer from "./ASMDashboard";
import countryHeadReducer from "./CountryHead";
import chartReducer from "./chart";
import dcReducer from "./DiscussionCard";
import escalationReducer from "./Escalation";
import feedbackReducer from "./Feedback";
import mappingReducer from "./Mapping";
import attendanceReducer from "./attendance";
import visitHistoryReducer from "./visitHistory";
import ndrReducer from "./NDR";
import managerReducer from "./managers";
import motivatorReducer from "./motivator";
import pincodeReducer from "./PincodeManagement";
import featureSwitchReducer from "./FeatureSwitch";

export default combineReducers({
  auth: authReducer, // Authentication
  bde: bdeReducer, // BDE Management
  retailer: retailerReducer, // Retailer Management
  rsm: regionalManagerReducer, // Regional Manager
  geo: geoReducer, // Pincode, States, Area and Regions
  asmDashboard: asmDashboardReducer, // Area Sales Manager
  ch: countryHeadReducer, // Country Head
  chart: chartReducer, // Chart
  dc: dcReducer, // Discussion Card
  escalation: escalationReducer, // Escalation
  feedback: feedbackReducer, //Feedback
  mapping: mappingReducer, // Mapping
  ndr: ndrReducer,
  attendance: attendanceReducer, // Attendance
  visitHistory: visitHistoryReducer, // Visit History
  managers: managerReducer, // Manager Reducer
  motivator: motivatorReducer, // Motivator Reducer
  pincode: pincodeReducer, // Pincode Reducer
  featureSwitch: featureSwitchReducer, // Feature Switch Reducer
});
