// Remove Duplicated from the array
export const uniqueArray = (array: any[]) => {
  return array.filter(function (item, pos) {
    return array.indexOf(item) === pos;
  });
};

export const uniqPinCodesRM = (array: any[]) => {
  const uniqPin = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item.slug)) {
      map.set(item.slug, true); // set any value to Map
      uniqPin.push({
        slug: item.slug,
        name: item.name,
        pincode: item.pincode,
      });
    }
  }
  return uniqPin;
};

export const uniqAsmRM = (array: any[]) => {
  const uniqAsm = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item.username)) {
      map.set(item.username, true); // set any value to Map
      uniqAsm.push({
        username: item.username,
        name: item.name,
        is_active: item.is_active,
      });
    }
  }
  return uniqAsm;
};

export const uniqPinCodes = (array: any[]) => {
  let uniqPin = [];
  let map = new Map();
  if (Array.isArray(array)) {
    if (!array.some((item) => item === undefined)) {
      for (const item of array) {
        if (!map.has(item.slug)) {
          map.set(item.slug, true); // set any value to Map
          uniqPin.push({
            slug: item.slug,
            name: item.name,
            pincode: item.pincode,
          });
        }
      }
      return uniqPin;
    }
  }
};

export const uniqAllSelectedPinsAndSegs = (array: any[]) => {
  const uniqPinsAndSags = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item.pincode)) {
      map.set(item.pincode, true); // set any value to Map
      uniqPinsAndSags.push({
        pincode: item.pincode,
        category: item.category,
      });
    }
  }
  return uniqPinsAndSags;
};

export const uniqRetailers = (array: any[]) => {
  const uniqRetailer = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item?.merchant_id)) {
      map.set(item?.merchant_id, true); // set any value to Map
      uniqRetailer.push({
        merchant_id: item?.merchant_id,
        business_name: item?.business_name,
        customer_segment: item?.customer_segment,
        customer_segment_name: item?.customer_segment_name,
        pincode: item?.pincode,
      });
    }
  }
  return uniqRetailer;
};

export const insert = (arr: any[], index: number, newItem: any) => {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
};
